import React from "react";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import Autocomplete from "react-google-autocomplete";
import Api from "../../../Services/api";

class EditLocation extends React.Component{


	componentDidMount = async()=>{
		this.resetState();
	}


	resetState = async()=>{
		this.setState({
			'display_name' : "",
			'location' : "",
			'latitude' : "",
			'longitude' : ""
 		});
	}


	constructor(props){
		super(props);
		this.state={
			'display_name' : "",
			'location' : "",
			'latitude' : "",
			'longitude' : "",
            'id' : ""
		}
	}


	closeEditModel = async()=>{
		this.props.closeEditModel();
	}


	updateLocation = async()=>{
		var data = {
            'location' : this.state.location,
            'display_name' : this.state.display_name,
            'latitude' : this.state.latitude,
            'longitude' : this.state.longitude
        }
		var response = await Api.PostRequest(data, 'location/'+this.state.id);
		if(response.status == 200){
			toast.success(response.message);
			this.props.closeEditModel();
		}
		else{
			toast.error(response.message);
		}
	}



    componentWillReceiveProps = async(nextProps)=>{
		
        this.setState({
            'display_name' : nextProps.location.display_name,
            'location' : nextProps.location.location,
            'latitude' : nextProps.location.latitude,
            'id' : nextProps.location.id,
			'longitude' : nextProps.location.longitude
        });
    }




	render(){
		return(
			<Modal show={this.props.show_edit_model} onHide={() => this.closeEditModel()}>
				<Modal.Header>
					<h5>Edit Location</h5>
				</Modal.Header>
				<Modal.Body>
					<div class="reservation-page-section">
						<div class="container">
							<div class="reservation-detais form-inner">
								<div class="row form-wrap">
									<div className="col-12 form-group">
										<label>Display Name</label>
										<input className="form-control" value={this.state.display_name} onChange={(event) => {this.setState({'display_name': event.target.value})}} type="text" placeholder=""/>
									</div>
									
                                    <div className="col-12 form-group">
										<label>Location</label>
										<Autocomplete
											apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
											onPlaceSelected={(place) => {
												this.setState({
													'location' : place.formatted_address,
													'latitude' : place.geometry.location.lat(),
													'longitude' : place.geometry.location.lng(),
												})
											}}
											options={{
												types: ["(regions)"],
												componentRestrictions: { country: "in" },
											}}
											defaultValue={this.state.location}
											style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }}
										/>
									</div>

									<div class="col-12 form-group">
										<label>Latitude</label>
										<input className="form-control" disabled={true} value={this.state.latitude} onChange={(event) => {this.setState({'latitude': event.target.value})}} type="text" placeholder=""/>
									</div>

									<div class="col-12 form-group">
										<label>Longitude</label>
										<input className="form-control" disabled={true} value={this.state.longitude} onChange={(event) => {this.setState({'longitude': event.target.value})}} type="text" placeholder=""/>
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" class="btn btn-danger" onClick={()=>{this.closeEditModel()}}>Close</button>
					<button type="submit" class="btn btn-success" onClick={()=>{this.updateLocation()}}>Submit</button>
				</Modal.Footer>
			</Modal>
		)
	}

}

				
export default EditLocation