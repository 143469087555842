import logo from "./logo.svg";
import "./App.css";
import Api from "./Services/api";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from './utils/AuthContext';

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Dashboard from "./components/dashboard/Dashboard";
import Default from "./components/default/Default";

import Notifications from "./components/notifications/Notifications";

import StringTypes from "./components/string_services/string_types/StringTypes";
import NewStringType from "./components/string_services/string_types/NewStringType";

import StringVendors from "./components/string_services/string_vendors/StringVendors";
import StringServiceBookings from "./components/string_services/string_service_bookings/StringServiceBookings";
import StringServiceBooking from "./components/string_services/string_service_bookings/StringServiceBooking";
import Login from "./components/login/Login";
import StringAddons from "./components/string_services/string_addons/string_addons";
import NewStringAddon from "./components/string_services/string_addons/new_string_addon";
import EditStringAddon from "./components/string_services/string_addons/edit_string_addon";
import EditStringType from "./components/string_services/string_types/EditStringType";

import Categories from "./components/ecommerce/Categories/Categories";
import SubCategories from "./components/ecommerce/SubCategories/SubCategories";

import Products from "./components/ecommerce/Products/Products";
import NewProduct from "./components/ecommerce/Products/NewProduct";
import EditProduct from "./components/ecommerce/Products/EditProduct";
import EcommerceOrders from "./components/ecommerce/Orders/Orders";
import EcommerceOrder from "./components/ecommerce/Orders/Order";

import Locations from "./components/settings/locations/locations";

import Coupons from "./components/settings/coupons/coupons";
import NewCoupon from "./components/settings/coupons/NewCoupon";
import EditCoupon from "./components/settings/coupons/EditCoupon";

import Sports from "./components/stadiums/sports/Sports";
import Stadiums from './components/stadiums/stadiums/stadiums';
import NewStadium from './components/stadiums/stadiums/NewStadium';
import EditStadium from './components/stadiums/stadiums/EditStadium';

import Banners from './components/Banners/Banners';
import DeliveryBoys from "./components/delivery_boys/DeliveryBoys";


function App() {

  useEffect(() => {
    getUser();
  })



  const getUser = async () => {
    var token = (await localStorage.getItem("token")) || "";
    if (token) {
      setIsLoggedIn(true)
    }
    else {
      setIsLoggedIn(false)
    }
  }


  const [loggedIn, setIsLoggedIn] = React.useState(false);


  const authContext = React.useMemo(
    () => ({
      signIn: async () => {
        await setIsLoggedIn(true)
      },
      signOut: async () => {
        await setIsLoggedIn(false)
      }
    }),
  );



  return (
    <AuthContext.Provider value={authContext}>
      <Router>
        <div className="App">
          {!loggedIn && (
            <Routes>
              <Route path="/" exact element={<Login/>} />
            </Routes>
          )}
          {loggedIn && (
            <>
              <Header/>
              <Routes>
                <Route path="/dashboard" exact element={<Dashboard />} />
                <Route path="/" exact element={<Dashboard />} />
                <Route path="/default" exact element={<Default />} />
                <Route path="/notifications" exact element={<Notifications />} />

                <Route path="/banners" exact element={<Banners />} />

                <Route path="/string-types" exact element={<StringTypes />} />
                <Route path="/string-types/new-string-type" exact element={<NewStringType />} />
                <Route path="/string-types/edit-string-type/:id" exact element={<EditStringType />} />

                <Route path="/string-vendors" exact element={<StringVendors />} />
                <Route path="/string-service-bookings" exact element={<StringServiceBookings />} />
                <Route path="/string-service-booking/:id" exact element={<StringServiceBooking />} />
                <Route path="/string-addons" excat element={<StringAddons />} />
                <Route path="/new-string-addon" excat element={<NewStringAddon />} />
                <Route path="/edit-string-addon/:id" excat element={<EditStringAddon />} />

                <Route path="/ecommerce/categories" excat element={<Categories />} />
                <Route path="/ecommerce/sub-categories" excat element={<SubCategories />} />
                <Route path="/ecommerce/products" excat element={<Products />} />
                <Route path="/ecommerce/new-product" excat element={<NewProduct />} />
                <Route path="/ecommerce/edit-product/:id" excat element={<EditProduct />} />
                <Route path="/ecommerce/orders" excat element={<EcommerceOrders />} />
                <Route path="/ecommerce/order/:id" excat element={<EcommerceOrder />} />

                <Route path="/locations" excat element={<Locations />} />

                <Route path="/coupons" excat element={<Coupons />} />
                <Route path="/coupons/new-coupon" exact element={<NewCoupon />} />
                <Route path="/coupons/edit-coupon/:id" exact element={<EditCoupon />} />

                <Route path="/stadiums/sports" excat element={<Sports />} />
                <Route path="/stadiums/stadiums" excat element={<Stadiums />} />
                <Route path="/stadiums/new-stadium" excat element={<NewStadium />} />
                <Route path="/stadiums/edit-stadium/:id" excat element={<EditStadium />} />

                <Route path="/delivery-boys" exact element={<DeliveryBoys />} />

              </Routes>
            </>
          )}
          <ToastContainer />
          <Footer />
        </div>
      </Router>

    </AuthContext.Provider>

  );
}

export default App;
