import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';

import NewSport from "./NewSport";
import EditSport from "./EditSport";

class Sports extends Component {

	componentDidMount=()=>{
		this.getSports();
	}


	getSports = async()=>{
		var response = await Api.GetRequest({}, "stadiums/sports");
		if(response.status == 200){
			this.setState({'sports' : response.sports});
		}
		else{

		}
	}


	constructor(props) {
		super(props);
		this.state = {
			'show_add_model': false,
            'show_edit_model': false,
			'sports' : [],
            'sport' : {
                'id' : "",
                'name' : "",
                'status' : ""
            }
		};
	}


	closeAddModel = async()=>{
		this.setState({'show_add_model' : false});
		await this.getSports();
	}



	openEditModel = async(sport)=>{
		var sport = {
			'id' : sport.id,
			'name' : sport.name,
			'status' : sport.status
		}

		this.setState({
			'sport' : sport,
			'show_edit_model' : true
		});
	}


	closeEditModel = async()=>{
		this.setState({'show_edit_model' : false});
		await this.getSports();
	}


	deleteCategory = async(sport_id)=>{
		var response = await Api.DeleteRequest('stadiums/sport/'+sport_id);
		if(response.status == 200){
			toast.success(response.message);
			this.getSports();
		}
		else{
			toast.error(response.message);
		}
	}


	render() {
		return (
			<>
				<NewSport show_add_model={this.state.show_add_model} closeAddModel={this.closeAddModel}/>
				<EditSport show_edit_model={this.state.show_edit_model} sport={this.state.sport} closeEditModel={this.closeEditModel}/>

				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">Stadiums</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">Sports</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										<button type="button" class="btn btn-success" onClick={()=>{this.setState({'show_add_model' :  true})}}>
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> Sport
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
                                        <div class="dt-responsive table-responsive">
                                            <table id="full-row-reorder" class="table table-striped table-bordered nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>S. No.</th>
                                                        <th>Name</th>
														<th>Image</th>
                                                        <th>Status</th>
                                                        <th>Created At</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                    {this.state.sports.map((sport, index)=>{
                                                        return(
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{sport.name}</td>
																<td><img src={process.env.REACT_APP_IMAGE_URL + sport.image} className="img img-fluid my-3" style={{'width':'50px'}} /></td>
                                                                <td>
                                                                    {sport.status == 0 &&
                                                                        <span className="badge badge-danger">In active</span>
                                                                    }
                                                                    {sport.status == 1 &&
                                                                        <span className="badge badge-success">Active</span>
                                                                    }
                                                                </td>
                                                                <td>{moment(sport.created_at).format('lll')}</td>
                                                                <td>
                                                                    <button onClick={()=>{this.openEditModel(sport)}} className="btn btn-info btn-sm mx-2"><i className="feather icon-edit"></i>&nbsp;Edit </button>
                                                                    <button onClick={()=>{this.deleteCategory(sport.id)}} className="btn btn-danger btn-sm mx-2"><i className="feather icon-trash-2"></i>&nbsp;Delete </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default Sports;
