import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';

import NewLocation from "./NewLocation";
import EditLocation from "./EditLocation";

class Locations extends Component {

	componentDidMount=()=>{
		this.getLocations();
	}


	getLocations = async()=>{
		var response = await Api.GetRequest({}, "locations");
		if(response.status == 200){
			this.setState({'locations' : response.locations});
		}
		else{

		}
	}


	constructor(props) {
		super(props);
		this.state = {
			'show_add_model': false,
            'show_edit_model': false,
			'locations' : [],
            'location' : {
                'id' : "",
                'display_name' : "",
                'location' : "",
                'latitude' : 0.0,
                'longitude' : 0.0
            }
		};
	}


	closeAddModel = async()=>{
		this.setState({'show_add_model' : false});
		await this.getLocations();
	}



	openEditModel = async(location)=>{
		var location = {
			'id' : location.id,
			'display_name' : location.display_name,
            'location' : location.location,
            'latitude' : location.latitude,
            'longitude' : location.longitude
		}

		this.setState({
			'location' : location,
			'show_edit_model' : true
		});
	}


	closeEditModel = async()=>{
		this.setState({'show_edit_model' : false});
		await this.getLocations();
	}


	deleteLocation = async(location_id)=>{
		var response = await Api.DeleteRequest('location/'+location_id);
		if(response.status == 200){
			toast.success(response.message);
			this.getLocations();
		}
		else{
			toast.error(response.message);
		}
	}


	render() {
		return (
			<>
				<NewLocation  show_add_model={this.state.show_add_model} closeAddModel={this.closeAddModel}/>
				<EditLocation  show_edit_model={this.state.show_edit_model} location={this.state.location} closeEditModel={this.closeEditModel}/>

				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">Extra</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">Locations</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										<button type="button" class="btn btn-success" onClick={()=>{this.setState({'show_add_model' :  true})}}>
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> Location
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
									<div class="dt-responsive table-responsive">
										<table id="full-row-reorder" class="table table-striped table-bordered nowrap">
											<thead>
												<tr>
													<th>S. No.</th>
													<th>Display Name</th>
													<th>Location</th>
													<th>Latitude</th>
													<th>Longitude</th>
													<th>Action</th>
												</tr>
											</thead>
											
											<tbody>
												{this.state.locations.map((location, index)=>{
													return(
														<tr>
															<td>{index + 1}</td>
															<td>{location.display_name}</td>
                                                            <td>{location.location}</td>
                                                            <td>{location.latitude}</td>
                                                            <td>{location.longitude}</td>
															<td>
																<button onClick={()=>{this.openEditModel(location)}} className="btn btn-info btn-sm mx-2"><i className="feather icon-edit"></i>&nbsp;Edit </button>
                                            					<button onClick={()=>{this.deleteLocation(location.id)}} className="btn btn-danger btn-sm mx-2"><i className="feather icon-trash-2"></i>&nbsp;Delete </button>
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Locations;
