import React from "react";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

import Api from "../../../Services/api";

class EditSport extends React.Component{

	status_list = [
		{'value': 0, 'label': 'In Active'},
		{'value': 1, 'label': 'Active'},
	]


	componentDidMount = async()=>{
		this.resetState();
	}


	resetState = async()=>{
		this.setState({
			'name' : "",
			'status' : "",
			'image' : {}
 		});
	}


	constructor(props){
		super(props);
		this.state={
			'name' : "",
			'status' : "",
			'image' : {},
            'id' : "",
		}
	}


	closeEditModel = async()=>{
		this.props.closeEditModel();
	}


	updateSport = async()=>{
		var form_data = new FormData()
		form_data.append('name', this.state.name);
		form_data.append('status', this.state.status);
		if(this.state.image){
            form_data.append('image', this.state.image);
        }
		var response = await Api.PostRequestForm(form_data, 'stadiums/sport/'+this.state.id);
		if(response.status == 200){
			toast.success(response.message);
			this.props.closeEditModel();
		}
		else{
			toast.error(response.message);
		}
	}



    componentWillReceiveProps = async(nextProps)=>{
        this.setState({
            'name' : nextProps.sport.name,
            'status' : nextProps.sport.status,
            'id' : nextProps.sport.id
        });
    }

	render(){
		return(
			<Modal show={this.props.show_edit_model} onHide={() => this.closeEditModel()}>
				<Modal.Header>
					<h5>Edit Sport</h5>
				</Modal.Header>
				<Modal.Body>
					<div class="reservation-page-section">
						<div class="container">
							<div class="reservation-detais form-inner">
								<div class="row form-wrap">
									<div className="col-12 form-group">
										<label>Name</label>
										<input className="form-control" value={this.state.name} onChange={(event) => {this.setState({'name': event.target.value})}} type="text" placeholder=""/>
									</div>
									<div className="col-6 form-group">
										<label>Image</label>
										<input className="form-control" onChange={(event) => {this.setState({'image': event.target.files[0]})}} type="file" placeholder=""/>
									</div>
									<div class="col-6 form-group">
										<label>Status</label>
										<Select value={this.status_list[this.state.status]} options={this.status_list} onChange={(event)=>{this.setState({'status' : event.value})}}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" class="btn btn-danger" onClick={()=>{this.closeEditModel()}}>Close</button>
					<button type="submit" class="btn btn-success" onClick={()=>{this.updateSport()}}>Submit</button>
				</Modal.Footer>
			</Modal>
		)
	}

}

				
export default EditSport