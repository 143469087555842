import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";


export class Banner extends Component {

    upload_ref = React.createRef();

    status_list = [
		{ value: '0', label: 'Inactive' },
		{ value: '1', label: 'Active' }
    ]

    componentDidMount=()=>{
        this.getBanners();
    }  


    getBanners = async()=>{
        var response = await Api.GetRequest({}, "string-banners");
        if(response.status == 200){
            this.setState({'string_banners' : response.string_banners});
        }
        else{

        }
    }



    constructor(props) {
        super(props);
        this.state = {
            'string_banners' : [],
        };
    }



    uploadFile = async (event) => {
        var form_data = new FormData();
        form_data.append("image", event.target.files[0]);
        var response = await Api.PostRequestForm(form_data, "string-banners");
        if (response.status == 200) {
            toast.success("banner added sucessfully");
            this.getBanners()
        } else {
            toast.error(response.message);
        }
    };


    deleteBanner = async (id) => {
        var response = await Api.DeleteRequest('string-banner/'+id);
        if(response.status == 200){
            this.getBanners();
        }
        else{
            toast.error(response.message);
        }
    };




  render() {
    return (
        <>
            <div class="pcoded-main-container">
                <div class="pcoded-content">
                    <div class="page-header">
                        <div class="page-block">
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <div class="page-header-title">
                                        <h5 class="m-b-10 text-left">Banners</h5>
                                    </div>
                                    <ul class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
                                        <li class="breadcrumb-item"><a href="#!">Banners</a></li>
                                    </ul>
                                </div>
                                <div class="col-md-6 text-right">
                                    <button onClick={()=>{this.upload_ref.click()}} type="button" class="btn btn-success" >
                                        <i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> Add Banners
                                    </button>
                                    <input className="form-control" style={{'display' : 'none'}} ref={(ref) => this.upload_ref = ref}  onChange={(event) => {this.uploadFile(event)}} type="file" placeholder=""/>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-body">
                            <div class="row">
                                {this.state.string_banners.map((banner) =>
                                    <div key={banner.id} class="col-lg-4 col-sm-6">
                                        <div class="thumbnail mb-4">
                                            <div class="thumb">
                                                <div style={{position:"absolute",top:"10px",right:"10px"}}>
                                                    <button onClick={()=>{this.deleteBanner(banner.id)}} className="btn btn-danger btn-sm mx-2"><i className="feather icon-trash-2"></i> </button>
                                                </div>
                                                    <img src={process.env.REACT_APP_IMAGE_URL + banner.image} alt="" class="img-fluid img-thumbnail"/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
  }
}

export default Banner
