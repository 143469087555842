import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';

import NewCategory from "./NewCategory";
import EditCategory from "./EditCategory";

class Categories extends Component {

	componentDidMount=()=>{
		this.getCategories();
	}


	getCategories = async()=>{
		var response = await Api.GetRequest({}, "ecommerce/categories");
		if(response.status == 200){
			this.setState({'categories' : response.categories});
		}
		else{

		}
	}


	constructor(props) {
		super(props);
		this.state = {
			'show_add_model': false,
            'show_edit_model': false,
			'categories' : [],
            'category' : {
                'id' : "",
                'name' : "",
                'status' : "",
				'image' : {}
            }
		};
	}


	closeAddModel = async()=>{
		this.setState({'show_add_model' : false});
		await this.getCategories();
	}



	openEditModel = async(category)=>{
		var category = {
			'id' : category.id,
			'name' : category.name,
			'status' : category.status,
			'image' : {}
		}

		this.setState({
			'category' : category,
			'show_edit_model' : true
		});
	}


	closeEditModel = async()=>{
		this.setState({'show_edit_model' : false});
		await this.getCategories();
	}


	deleteCategory = async(category_id)=>{
		var response = await Api.DeleteRequest('ecommerce/category/'+category_id);
		if(response.status == 200){
			toast.success(response.message);
			this.getCategories();
		}
		else{
			toast.error(response.message);
		}
	}


	render() {
		return (
			<>
				<NewCategory  show_add_model={this.state.show_add_model} closeAddModel={this.closeAddModel}/>
				<EditCategory  show_edit_model={this.state.show_edit_model} category={this.state.category} closeEditModel={this.closeEditModel}/>

				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">Ecommerce</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">Categories</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										<button type="button" class="btn btn-success" onClick={()=>{this.setState({'show_add_model' :  true})}}>
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> Category
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
									<div class="dt-responsive table-responsive">
										<table id="full-row-reorder" class="table table-striped table-bordered nowrap">
											<thead>
												<tr>
													<th>S. No.</th>
													<th>Name</th>
													<th>Image</th>
													<th>Status</th>
													<th>Created At</th>
													<th>Action</th>
												</tr>
											</thead>
											
											<tbody>
												{this.state.categories.map((category, index)=>{
													return(
														<tr>
															<td>{index + 1}</td>
															<td>{category.name}</td>
															<td><img src={process.env.REACT_APP_IMAGE_URL + category.image} className="img img-fluid" style={{'width':'100px'}} /></td>
															<td>
																{category.status == 0 &&
																	<span className="badge badge-danger">In active</span>
																}
																{category.status == 1 &&
																	<span className="badge badge-success">Active</span>
																}
															</td>
															<td>{moment(category.created_at).format('lll')}</td>
															<td>
																<button onClick={()=>{this.openEditModel(category)}} className="btn btn-info btn-sm mx-2"><i className="feather icon-edit"></i>&nbsp;Edit </button>
                                            					<button onClick={()=>{this.deleteCategory(category.id)}} className="btn btn-danger btn-sm mx-2"><i className="feather icon-trash-2"></i>&nbsp;Delete </button>
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Categories;
