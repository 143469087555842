import React from "react";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css';
import withRouter from "../../../Services/withRouter";
import moment from "moment";
import Api from "../../../Services/api";


class NewCoupon extends React.Component {


    status_list = [
		{'value': 0, 'label': 'In Active'},
		{'value': 1, 'label': 'Active'},
	]

	
	componentDidMount = async()=>{

	}


	constructor(props){
		super(props);
		this.state={
			'title' : "",
			'code' : "",
			'discount' : "",
			'status' : "",
			'expiry_date' : "",
            'min_cart_value' : 0
		}
	}



    addCoupon = async()=>{
		var data = {
            'title' : this.state.title,
			'code' : this.state.code,
			'discount' : this.state.discount,
			'status' : this.state.status,
			'expiry_date' : moment(this.state.expiry_date).format('YYYY-MM-DD'),
            'min_cart_value' : this.state.min_cart_value
        }
		var response = await Api.PostRequest(data, 'coupons');
		if(response.status == 200){
			toast.success(response.message);
			this.props.navigate('/coupons')
		}
		else{
			toast.error(response.message);
		}
	}



	render() {
		return (

			<>
				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-12">
										<div class="page-header-title">
											<h5 className="m-b-10 text-left">Coupons</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">New Coupon</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
                                        <div class="row form-wrap">
                                            <div className="col-6 form-group">
                                                <label className="text-left" style={{'width' : '100%'}}>Title</label>
                                                <input className="form-control" value={this.state.title} onChange={(event) => {this.setState({'title': event.target.value})}} type="text" placeholder=""/>
                                            </div>

                                            <div className="col-6 form-group">
                                                <label className="text-left" style={{'width' : '100%'}}>Code</label>
                                                <input className="form-control" value={this.state.code} onChange={(event) => {this.setState({'code': event.target.value})}} type="text" placeholder=""/>
                                            </div>
                                            
                                            <div class="col-6 form-group">
                                                <label className="text-left" style={{'width' : '100%'}}>Status</label>
                                                <Select options={this.status_list} onChange={(event)=>{this.setState({'status' : event.value})}}/>
                                            </div>

                                            <div className="col-6 form-group">
                                                <label className="text-left" style={{'width' : '100%'}}>Min Cart Value</label>
                                                <input className="form-control" onChange={(event)=>{this.setState({'min_cart_value' : event.target.value})}} min={0} type="number" placeholder=""/>
                                            </div>

                                            
                                            <div className="col-6 form-group">
                                                <label className="text-left" style={{'width' : '100%'}}>Discount</label>
                                                <input className="form-control" onChange={(event)=>{this.setState({'discount' : event.target.value})}} min={0} type="number" placeholder=""/>
                                            </div>


                                            <div className="col-6 form-group">
                                                <label className="text-left" style={{'width' : '100%'}}>Expiry Date</label>
                                                <input className="form-control" onChange={(event)=>{this.setState({'expiry_date' : event.target.value})}}  type="date" placeholder=""/>
                                            </div>


											<div className="col-12 ">
                                                <button type="submit" class="btn btn-success" onClick={()=>{this.addCoupon()}}>Submit</button>
                                            </div>
                                        </div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>

		)
	}
}

export default withRouter(NewCoupon);

