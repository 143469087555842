import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';
import Select from 'react-select';
import withRouter from "../../../Services/withRouter";

class EditStringAddon extends Component {

	componentDidMount=()=>{
		this.getStringAddon();
	}


    getStringAddon = async()=>{
        let id = this.props.params.id;
        if(id){
			var response = await Api.GetRequest({}, 'string-addon/'+id);
            if(response.status == 200){
                this.setState({
					'id' : this.props.params.id,
					'name' : response.string_addon.name,
					'price' : response.string_addon.price,
					'status' : response.string_addon.status,
					'description' : response.string_addon.description
				});	
            }
            else{
                
            }
		}
		else{
			
		}
    }


    status_list = [
		{'value': 0, 'label': 'In Active'},
		{'value': 1, 'label': 'Active'},
	]


	constructor(props) {
		super(props);
		this.state = {
			"id" : "",
			"name" : "",
            "image" : {},
            "price" : 0,
            "status" : "",
            "description" : ""
		};
	}


	updateStringAddons = async()=>{
        var form_data = new FormData();
		
		if(Object.keys(this.state.image).length > 0){
			form_data.append('image', this.state.image);
		}
		form_data.append('name', this.state.name);
		form_data.append('price', this.state.price);
		form_data.append('status', this.state.status);
		form_data.append('description', this.state.description);

		var response = await Api.PostRequestForm(form_data, 'string-addon/'+this.state.id);
		if(response.status == 200){
			toast.success(response.message);
			this.props.navigate('string-addons');
		}
		else{
			toast.error(response.message);
		}
    }


	render() {
		return (
			<>
				
				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">String Addons</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">String Addons</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										{/* <button type="button" class="btn btn-success" onClick={()=>{this.setState({'show_add_model' :  true})}}>
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> String Type
										</button> */}
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
                                        <div className="row">
                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Name</label>
                                                <input className="form-control" value={this.state.name} onChange={(event) => {this.setState({'name': event.target.value})}} type="text" placeholder=""/>
                                            </div>
                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Price</label>
                                                <input className="form-control" value={this.state.price} onChange={(event) => {this.setState({'price': event.target.value})}} type="text" placeholder=""/>
                                            </div>
                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Image</label>
                                                <input className="form-control" onChange={(event)=>{this.setState({'image' : event.target.files[0]});}}  type="file" placeholder=""/>
                                            </div>
                                            <div class="col-6 my-2 form-group">
                                                <label className="text-left w-100">Status</label>
                                                <Select className="text-left" options={this.status_list} onChange={(event)=>{this.setState({'status' : event.value})}}/>
                                            </div>

											

                                            <div class="col-12 my-2 form-group">
                                                <label className="text-left w-100">Description</label>
                                                <textarea className="form-control" name="description" value={this.state.description} onChange={(event)=>{this.setState({'description' : event.target.value})}}></textarea>
                                            </div>
                                            <div className="col-12 ">
                                                <button type="submit" class="btn btn-success" onClick={()=>{this.updateStringAddons()}}>Submit</button>
                                            </div>
                                        </div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(EditStringAddon);
