import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';


class Coupons extends Component {

	componentDidMount=()=>{
		this.getCoupons();
	}


	getCoupons = async()=>{
		var response = await Api.GetRequest({}, "coupons");
		if(response.status == 200){
			this.setState({'coupons' : response.coupons});
		}
		else{

		}
	}


	constructor(props) {
		super(props);
		this.state = {
			'coupons' : []
		};
	}



	deleteCoupon = async(coupon_id)=>{
		var response = await Api.DeleteRequest('coupon/'+coupon_id);
		if(response.status == 200){
			toast.success(response.message);
			this.getCoupons();
		}
		else{
			toast.error(response.message);
		}
	}


	render() {
		return (
			<>
				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">Coupons</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">Coupons</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										<Link type="button" class="btn btn-success" to={'/coupons/new-coupon'}>
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> Coupon
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
									<div class="dt-responsive table-responsive">
										<table id="full-row-reorder" class="table table-striped table-bordered nowrap">
											<thead>
												<tr>
													<th>S. No.</th>
													<th>Title</th>
													<th>Code</th>
													<th>Discount</th>
                                                    <th>Expiry Date</th>
                                                    <th>Min Cart Value</th>
													<th>Status</th>
													<th>Created At</th>
													<th>Action</th>
												</tr>
											</thead>
											
											<tbody>
												{this.state.coupons.map((coupon, index)=>{
													return(
														<tr>
															<td>{index + 1}</td>
															<td>{coupon.title}</td>
															<td>{coupon.code}</td>
															<td>{coupon.discount}</td>
															<td>{moment(coupon.expiry_date).format('DD-MM-YYYY')}</td>
                                                            <td>{moment(coupon.min_cart_value).format('lll')}</td>
															<td>
																{coupon.status == 0 &&
																	<span className="badge badge-danger">In active</span>
																}
																{coupon.status == 1 &&
																	<span className="badge badge-success">Active</span>
																}
															</td>
															<td>{moment(coupon.created_at).format('lll')}</td>
															<td>
																<Link to={`/coupons/edit-coupon/${coupon.id}`} className="btn btn-info btn-sm mx-2"><i className="feather icon-edit"></i>&nbsp;Edit </Link>
                                            					<button onClick={()=>{this.deleteCoupon(coupon.id)}} className="btn btn-danger btn-sm mx-2"><i className="feather icon-trash-2"></i>&nbsp;Delete </button>
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Coupons;
