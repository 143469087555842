import React, { Component } from "react";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";

import { Link } from "react-router-dom";
import withRouter from "../../Services/withRouter";
import { AuthContext } from '../../utils/AuthContext';

export class Login extends Component {

	static contextType = AuthContext

	componentDidMount = async () => {
		this.checkUser();
	};

	checkUser = async () => {
		var token = (await localStorage.getItem("token")) || "";
		if (token) {
			this.context.signIn()
		}
		else {

		}
	};


	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
		};
	}



	login = async (event) => {
		event.preventDefault();
		var data = {
			email: this.state.email,
			password: this.state.password,
		};
		var response = await Api.PostRequest(data, "login");
		if (response.status === 200) {
			toast.success("Sucessfully login");
			this.setState({
				email: "",
				password: "",
			});
			await localStorage.setItem("token", response.token);
			this.context.signIn()
		} else {
			toast.error(response.message);
		}
	};



	render() {
		return (
			<div class="auth-wrapper">
				<div class="auth-content">
					<div class="card">
						<div class="row align-items-center text-center">
							<div class="col-md-12">
								<div class="card-body">
									<h4 class="mb-3 f-w-400">Log in to Driverzz</h4>
									<form id="author_form" onSubmit={this.login}>
										<div class="form-group mb-3">
											<input type="text" class="form-control" id="Email" placeholder=" email" value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} />
										</div>
										<div class="form-group mb-4">
											<input type="password" class="form-control" id="Password" placeholder="password" value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })} />
										</div>
										<div class="custom-control custom-checkbox text-left mb-4 mt-2">
											<input type="checkbox" class="custom-control-input" id="customCheck1" />
											<label class="custom-control-label" for="customCheck1">
												Save credentials.
											</label>
										</div>
										<button class="btn btn-block btn-primary mb-4" type="submit">
											Login
										</button>
									</form>

									<p class="mb-2 text-muted">
										Forgot password?{" "}
										<a href="auth-reset-password.html" class="f-w-400">
											Reset
										</a>
									</p>
									<p class="mb-0 text-muted">
										Don’t have an account?{" "}
										<a href="auth-signup.html" class="f-w-400">
											Signup
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Login;
