import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';

export class Notifications extends Component {

	componentDidMount=()=>{
		this.getNotifications();
	}

	getNotifications = async()=>{
		var response = await Api.GetRequest({}, "notifications");
		if(response.status == 200){
			this.setState({'notifications' : response.notifications});
		}
		else{

		}
	}

	receivers = [
		{ value: '2', label: 'Customers' },
		{ value: '3', label: 'Drivers' }
	  ]


	constructor(props) {
		super(props);
		this.state = {
			'show_notification_model': false,
			'title': "",
			'body': "",
			'send_to': "",
			'notifications' : []	
		};
	}


	sendNotification = async () => {
		var data = {
			'title': this.state.title,
			'body': this.state.body,
			'send_to': this.state.send_to,
		};
		var response = await Api.PostRequest(data, "notifications");
		if (response.status === 200) {
			toast.success(response.message);
			this.setState({
				'title': "",
				'body': "",
				'send_to': "",
				'show_notification_model' : false
			});
			this.getNotifications();
		}
		else {
			toast.error(response.message);
		}
	};



	

	handleCloseModel = () => {
		this.setState({ 'show_notification_model': false });
	};


	render() {
		return (
			<>
				<Modal show={this.state.show_notification_model} onHide={() => this.handleCloseModel()}>
					<Modal.Header>
						<h3>Send Custom Notification</h3>
					</Modal.Header>
			 		<Modal.Body>
			 			<div class="reservation-page-section">
			 				<div class="container">
								<div class="reservation-detais form-inner">
									
									<div class="row form-wrap">
										<div class="col-6 form-group">
											<label>Send To</label>
											<select className="form-control" onChange={(event)=>{this.setState({'send_to' : event.target.value})}}>
												<option value=""></option>
												{this.receivers.map((receiver)=>{
													return(
														<option value={receiver.value}>{receiver.label}</option>
													)
												})}
											</select>
										</div>
										<div className="col-6 form-group">
											<label>Title</label>
											<input className="form-control" value={this.state.title} onChange={(event) => {this.setState({ title: event.target.value })}} type="text" placeholder="Notification title" style={{ width: "100%" }}/>
										</div>

										<div class="col-12 form-group">
											<label>Body</label>
											<textarea className="form-control" value={this.state.body} onChange={(event) => {this.setState({ body: event.target.value })}} type="text" placeholder="Notification Body" style={{ width: "100%" }}/>
										</div>
									</div>
								</div>
			 				</div>
			 			</div>
			 		</Modal.Body>
			 		<Modal.Footer>
					 	<button type="button" class="btn btn-danger">Close</button>
					 	<button type="submit" class="btn btn-success" onClick={()=>{this.sendNotification()}}>Submit</button>
			 		</Modal.Footer>
			 	</Modal>

				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">Notifications</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">Notifications</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										<button type="submit" class="btn btn-success" onClick={()=>{this.setState({ 'show_notification_model': true })}}>
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i>Send notification
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
									<div class="dt-responsive table-responsive">
										<table id="full-row-reorder" class="table table-striped table-bordered nowrap">
											<thead>
												<tr>
													<th>S. No.</th>
													<th>Title</th>
													<th>body</th>
													<th>Created At</th>
												</tr>
											</thead>
											
											<tbody>
												{this.state.notifications.map((notification, index)=>{
													return(
														<tr>
															<td>{index + 1}</td>
															<td>{notification.title}</td>
															<td>{notification.body}</td>
															<td>{moment(notification.created_at).format('lll')}</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Notifications;
