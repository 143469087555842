import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';

import AddDeliveryBoy from "./AddDeliveryBoy";
import EditDeliveryBoy from "./EditDeliveryBoy";

class DeliveryBoys extends Component {

	componentDidMount=()=>{
		this.getDeliveryBoys();
	}


	getDeliveryBoys = async()=>{
		var response = await Api.GetRequest({}, "delivery-boys");
		if(response.status == 200){
			this.setState({'delivery_boys' : response.delivery_boys});
		}
		else{

		}
	}


	constructor(props) {
		super(props);
		this.state = {
			'show_add_model': false,
            'show_edit_model': false,
			'delivery_boys' : [],
            'delivery_boy' : {
                'id' : "",
                'name' : "",
                'mobile' : "",
                'status' : ""
            }
		};
	}


	closeAddModel = async()=>{
		this.setState({'show_add_model' : false});
		await this.getDeliveryBoys();
	}



	openEditModel = async(delivery_boy)=>{
		var delivery_boy = {
			'id' : delivery_boy.id,
			'name' : delivery_boy.name,
			'status' : delivery_boy.status,
            'mobile' : delivery_boy.mobile
		}

		this.setState({
			'delivery_boy' : delivery_boy,
			'show_edit_model' : true
		});
	}


	closeEditModel = async()=>{
		this.setState({'show_edit_model' : false});
		await this.getDeliveryBoys();
	}


	deleteDeliveryBoy = async(delivery_boy_id)=>{
		var response = await Api.DeleteRequest('delivery-boy/'+delivery_boy_id);
		if(response.status == 200){
			toast.success(response.message);
			this.getDeliveryBoys();
		}
		else{
			toast.error(response.message);
		}
	}

	render() {
		return (
			<>
				<AddDeliveryBoy  show_add_model={this.state.show_add_model} closeAddModel={this.closeAddModel}/>
				<EditDeliveryBoy  show_edit_model={this.state.show_edit_model} delivery_boy={this.state.delivery_boy} closeEditModel={this.closeEditModel}/>

				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">Delivery Boys</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">Delivery Boys</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										<button type="button" class="btn btn-success" onClick={()=>{this.setState({'show_add_model' :  true})}}>
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> Delivery Boy
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
									<div class="dt-responsive table-responsive">
										<table id="full-row-reorder" class="table table-striped table-bordered nowrap">
											<thead>
												<tr>
													<th>S. No.</th>
													<th>Name</th>
                                                    <th>Mobile</th>
													<th>Status</th>
													<th>Action</th>
												</tr>
											</thead>
											
											<tbody>
												{this.state.delivery_boys.map((delivery_boy, index)=>{
													return(
														<tr>
															<td>{index + 1}</td>
															<td>{delivery_boy.name}</td>
                                                            <td>{delivery_boy.mobile}</td>
															<td>
																{delivery_boy.status == 0 &&
																	<span className="badge badge-danger">In active</span>
																}
																{delivery_boy.status == 1 &&
																	<span className="badge badge-success">Active</span>
																}
															</td>
															<td>
																<button onClick={()=>{this.openEditModel(delivery_boy)}} className="btn btn-info btn-sm mx-2"><i className="feather icon-edit"></i>&nbsp;Edit </button>
                                            					<button onClick={()=>{this.deleteDeliveryBoy(delivery_boy.id)}} className="btn btn-danger btn-sm mx-2"><i className="feather icon-trash-2"></i>&nbsp;Delete </button>
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default DeliveryBoys;
