import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';
import Select from 'react-select';

class EcommerceOrders extends Component {

	componentDidMount=async ()=>{
		await this.getOrders();
		await this.getDeliveryBoys();
	}


	getDeliveryBoys = async ()=>{
		var response = await Api.GetRequest({}, "delivery-boys/list");
		if(response.status == 200){
			this.setState({'delivery_boys' : response.delivery_boys});
		}
		else{
			
		}
	}

	getOrders = async()=>{
		var response = await Api.GetRequest({}, "ecommerce/orders");
		if(response.status == 200){
			this.setState({'orders' : response.orders});
		}
		else{

		}
	}


	constructor(props) {
		super(props);
		this.state = {
			'orders' : [],
			'delivery_boys' : []
		};
	}



	assignBookingToDeliveryBoy = async(booking_id, delivery_boy)=>{
		var data = {
			'booking_id' : booking_id,
			'delivery_boy' : delivery_boy.value
		}

		var response = await Api.PostRequest(data, 'ecommerce-order/delivery-boy/assign');
		if(response.status == 200){
			toast.success("Delivery boy assigned successfully.");
			await this.getOrders();
		}
		else{
			toast.error(response.message);
		}
	}
	
	
	render() {
		return (
			<>
				
				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">Orders</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">Orders</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										{/* <button type="button" class="btn btn-success" onClick={()=>{this.setState({'show_add_model' :  true})}}>
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> String Type
										</button> */}
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
									<div class="dt-responsive table-responsive">
										<table id="full-row-reorder" class="table table-striped table-bordered nowrap">
											<thead>
												<tr>
													<th>S. No.</th>
													<th>Order ID</th>
													<th>Name</th>
													<th>Mobile</th>
													<th>Price</th>
													<th>Discount</th>
													<th>Order Status</th>
													<th>Payment Status</th>
													<th>Delivery Boy</th>
													<th>Ordered At</th>
													<th></th>
												</tr>
											</thead>
											
											<tbody>
												{this.state.orders.map((order, index)=>{
													return(
														<tr>
															<td>{index + 1}</td>
															<td>{order.order_id}</td>
															<td>{order.name}</td>
															<td>{order.mobile}</td>
															<td className="text-right">₹ {order.price}</td>
															<td className="text-right">₹ {order.discount}</td>
															<td>
																{order.payment_status == 0 &&
																	<span className="badge badge-warning">Pending</span>
																}
																{order.payment_status == 1 &&
																	<span className="badge badge-info">Assigned</span>
																}
															</td>
															<td>
																{order.status == 0 &&
																	<span className="badge badge-warning">Pending</span>
																}
																{order.status == 1 &&
																	<span className="badge badge-info">Assigned</span>
																}
																{order.status == 2 &&
																	<span className="badge badge-success">Completed</span>
																}
															</td>
															<td>
																<Select 
																	menuPortalTarget={document.body}
																	value = {
																		this.state.delivery_boys.filter(option => 
																			option.value == order.delivery_boy_id)
																		} 
																	onChange={(event) => {this.assignBookingToDeliveryBoy(order.id, event)}} 
																	options={this.state.delivery_boys}/>
															</td>
															<td className="text-right">{moment(order.created_at).format('lll')}</td>
															<td className="text-right">
																<Link to={`/ecommerce/order/${order.id}`} className="btn btn-info btn-sm mx-2"><i className="feather icon-eye"></i>&nbsp;View </Link>
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default EcommerceOrders;
