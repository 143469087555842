import React from "react";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import Api from "../../../Services/api";
import withRouter from "../../../Services/withRouter";
import Autocomplete from "react-google-autocomplete";
import TagsInput from 'react-tagsinput';

class NewStadium extends React.Component{

	status_list = [
		{'value': 0, 'label': 'In Active'},
		{'value': 1, 'label': 'Active'},
	]

	
	componentDidMount = async()=>{
        this.getSports();
	}



    getSports = async() =>{
		var response = await Api.GetRequest({}, 'stadiums/sports/list');
		if(response.status == 200){
			this.setState({'sports': response.sports})
		}
		else{
			toast.success(response.message);
		}
	}




	constructor(props){
		super(props);
		this.state={
			'sports' : [],
            'owner_name' : "",
            'image' : {},
            'price' : 0,
            'status' : "",
            'address' : "",
            'latitude' : "",
            'longitude' : "",
            'description' : "",
            'referral_code':"",
            'stadium_name' : "",
            'email' : "",
            'mobile' : "",
            'sport' : [],
            'amenities' : [],
            'opening_time' : "",
            'closing_time' : ""
		}
	}


	
	addStadium = async()=>{
		var form_data = new FormData()
        form_data.append('stadium_name', this.state.stadium_name);
        form_data.append('image', this.state.image);
        form_data.append('price', this.state.price);
        form_data.append('status', this.state.status);
        form_data.append('address', this.state.address);
        form_data.append('latitude', this.state.latitude);
        form_data.append('longitude', this.state.longitude);
        form_data.append('description', this.state.description);
        form_data.append('owner_name', this.state.owner_name);
        form_data.append('referral_code', this.state.referral_code);
        form_data.append('email', this.state.email);
        form_data.append('mobile', this.state.mobile);
        form_data.append('stadium_sports', JSON.stringify(this.state.sport));
        form_data.append('opening_time', this.state.opening_time);
        form_data.append('closing_time', this.state.closing_time);
        form_data.append('amenities', this.state.amenities.toString());
		var response = await Api.PostRequestForm(form_data, 'stadiums/stadiums');
		if(response.status == 200){
			toast.success(response.message);
            this.props.navigate('/stadiums/stadiums')
		}
		else{
			toast.error(response.message);
		}
	}




	render(){
		return(
			<>
                <div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">Stadiums</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">New Stadium</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										{/* <button type="button" class="btn btn-success" onClick={()=>{this.setState({'show_add_model' :  true})}}>
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> String Type
										</button> */}
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
                                        <div className="row">
                                            
                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Name</label>
                                                <input className="form-control" value={this.state.stadium_name} onChange={(event) => {this.setState({'stadium_name': event.target.value})}} type="text" placeholder=""/>
                                            </div>
                                            
                                            

                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Image</label>
                                                <input className="form-control" onChange={(event)=>{this.setState({'image' : event.target.files[0]})}}  type="file" placeholder=""/>
                                            </div>

                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Opening Time</label>
                                                <input className="form-control" onChange={(event)=>{this.setState({'opening_time' : event.target.value})}}  type="time" placeholder=""/>
                                            </div>

                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Closing Time</label>
                                                <input className="form-control" onChange={(event)=>{this.setState({'closing_time' : event.target.value})}}  type="time" placeholder=""/>
                                            </div>

                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Price</label>
                                                <input className="form-control" value={this.state.price} onChange={(event) => {this.setState({'price': event.target.value})}} type="number" placeholder=""/>
                                            </div>

                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Referral Code</label>
                                                <input className="form-control" value={this.state.referral_code} onChange={(event) => {this.setState({'referral_code': event.target.value})}} type="text" placeholder=""/>
                                            </div>

                                            <div class="col-6 my-2 form-group">
                                                <label className="text-left w-100">Status</label>
                                                <Select className="text-left" options={this.status_list} onChange={(event)=>{this.setState({'status' : event.value})}}/>
                                            </div>
                                            
                                            <div class="col-6 my-2 form-group">
                                                <label className="text-left w-100">Sport</label>
                                                <Select className="text-left" options={this.state.sports}  onChange={(event)=>{this.setState({'sport' : event})}} isMulti={true}/>
                                            </div>

                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Location</label>
                                                <Autocomplete
                                                    apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                                    onPlaceSelected={(place) => {
                                                        this.setState({
                                                            'address' : place.formatted_address,
                                                            'latitude' : place.geometry.location.lat(),
                                                            'longitude' : place.geometry.location.lng(),
                                                        })
                                                    }}
                                                    options={{
                                                        types: ["(regions)"],
                                                        componentRestrictions: { country: "in" },
                                                    }}
                                                    defaultValue=""
                                                    style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "38px", paddingTop : 0, paddingLeft: "10px", paddingRight : "10px" }}
                                                />
                                            </div>
                                            
                                            <div className="col-12 my-2 text-left form-group">
                                                <label className="text-left w-100">Amenities</label>
                                                <TagsInput  value={this.state.amenities} onChange={(value)=>{this.setState({'amenities' : value})}} />
                                            </div>

                                            
                                            <div class="col-12 py-3 mt-5 justify-content-between d-flex align-items-center flex-row" style={{"backgroundColor": "#EDEDED"}}>
                                                <h5 class="my-0">Owner Info</h5>
                                            </div>
                                            
                                            <div className="col-4 my-4 form-group">
                                                <label className="text-left w-100">Owner Name</label>
                                                <input className="form-control" value={this.state.owner_name} onChange={(event) => {this.setState({'owner_name': event.target.value})}} type="text" placeholder=""/>
                                            </div>

                                            <div className="col-4 my-4 form-group">
                                                <label className="text-left w-100">Email</label>
                                                <input className="form-control" value={this.state.email} onChange={(event) => {this.setState({'email': event.target.value})}} type="text" placeholder=""/>
                                            </div>
                                            
                                            <div className="col-4 my-4 form-group">
                                                <label className="text-left w-100">Mobile</label>
                                                <input className="form-control" value={this.state.mobile} onChange={(event) => {this.setState({'mobile': event.target.value})}} type="text" placeholder=""/>
                                            </div>
                                            
                                            <div class="col-12 py-3 mt-5 justify-content-between d-flex align-items-center flex-row" style={{"backgroundColor": "#EDEDED"}}>
                                                <h5 class="my-0">Description</h5>
                                            </div>

                                            <div class="col-12 my-4 form-group">
                                                <label className="text-left w-100">Description</label>
                                                <textarea className="form-control" name="description" value={this.state.description} onChange={(event)=>{this.setState({'description' : event.target.value})}}></textarea>
                                            </div>


                                            <div className="col-12 ">
                                                <button type="submit" class="btn btn-success" onClick={()=>{this.addStadium()}}>Submit</button>
                                            </div>
                                        </div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </>
		)
	}

}

				
export default withRouter(NewStadium)