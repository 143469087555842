import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';


class StringAddons extends Component {

	componentDidMount=()=>{
		this.getStringAddons();
	}


	getStringAddons= async()=>{
		var response = await Api.GetRequest({}, "string-addons");
		if(response.status == 200){
			this.setState({'string_addons' : response.string_addons});
		}
		else{

		}
	}



	deleteStringAddon = async(addon_id)=>{
		var response = await Api.DeleteRequest('string-addon/'+addon_id);
		if(response.status == 200){
			toast.success(response.message);
			this.getStringAddons();
		}
		else{
			toast.error(response.message);
		}
	}


	constructor(props) {
		super(props);
		this.state = {
			'string_addons' : []
		};
	}


	
	render() {
		return (
			<>
				
				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">String Addons</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">String Addons</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										<Link type="button" class="btn btn-success" to="/new-string-addon">
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> String Addon
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
									<div class="dt-responsive table-responsive">
										<table id="full-row-reorder" class="table table-striped table-bordered nowrap">
											<thead>
												<tr>
													<th>S. No.</th>
													<th>Image</th>
													<th>Name</th>
													<th>Description</th>
													<th>Price</th>
													<th>Status</th>
													<th>Action</th>
												</tr>
											</thead>
											
											<tbody>
												{this.state.string_addons.map((string_addon, index)=>{
													return(
														<tr>
															<td>{index + 1}</td>
															<td><img src={process.env.REACT_APP_IMAGE_URL + string_addon.image} className="img img-fluid" style={{'width':'100px'}} /></td>
															<td>{string_addon.description}</td>
															<td>{string_addon.name}</td>
															<td>{string_addon.price}</td>
															<td>
																{string_addon.status == 0 &&
																	<span className="badge badge-warning">Inactive</span>
																}
																{string_addon.status == 1 &&
																	<span className="badge badge-info">Active</span>
																}
															</td>
															<td>
																<Link to={`/edit-string-addon/${string_addon.id}`} className="btn btn-info btn-sm mx-2"><i className="feather icon-edit"></i>&nbsp;Edit </Link>
																<button onClick={()=>{this.deleteStringAddon(string_addon.id)}} className="btn btn-danger btn-sm mx-2"><i className="feather icon-trash-2"></i>&nbsp;Delete </button>
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default StringAddons;
