import React from "react";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import Api from "../../../Services/api";
import Autocomplete from "react-google-autocomplete";

class AddStringVendor extends React.Component{

	status_list = [
		{'value': 0, 'label': 'In Active'},
		{'value': 1, 'label': 'Active'},
	]

	
	componentDidMount = async()=>{
		this.resetState();
	}


	resetState = async()=>{
		this.setState({
			'name' : "",
			'status' : "",
			'email' : "",
			'mobile' : "",
			'address' : "",
			'latitude' : null,
			'longitude' : null
		});
	}


	constructor(props){
		super(props);
		this.state={
			'name' : "",
			'status' : "",
			'email' : "",
			'mobile' : "",
			'address' : "",
			'latitude' : null,
			'longitude' : null
		}
	}


	closeAddModel = async()=>{
		this.props.closeAddModel();
	}


	addStringVendor = async()=>{
		var data = {
			'name' : this.state.name.trim(),
			'status' : this.state.status,
			'email' : this.state.email,
			'mobile' : this.state.mobile,
			'address' : this.state.address,
			'latitude' : this.state.latitude,
			'longitude' : this.state.longitude
		}
		var response = await Api.PostRequest(data, 'string-vendors');
		if(response.status == 200){
			toast.success(response.message);
			this.props.closeAddModel();
		}
		else{
			toast.error(response.message);
		}
	}

	render(){
		return(
			<Modal show={this.props.show_add_model} onHide={() => this.closeAddModel()}>
				<Modal.Header>
					<h5>New String Vendor</h5>
				</Modal.Header>
				<Modal.Body>
					<div class="reservation-page-section">
						<div class="container">
							<div class="reservation-detais form-inner">
								<div class="row form-wrap">
									<div className="col-6 form-group">
										<label>Name</label>
										<input className="form-control" value={this.state.name} onChange={(event) => {this.setState({'name': event.target.value})}} type="text" placeholder=""/>
									</div>
									<div className="col-6 form-group">
										<label>Email</label>
										<input className="form-control" value={this.state.email} onChange={(event) => {this.setState({'email': event.target.value})}} type="text" placeholder=""/>
									</div>
									<div className="col-6 form-group">
										<label>Mobile</label>
										<input className="form-control" value={this.state.mobile} onChange={(event) => {this.setState({'mobile': event.target.value})}} type="text" placeholder=""/>
									</div>
									<div class="col-6 form-group">
										<label>Status</label>
										<Select options={this.status_list} onChange={(event)=>{this.setState({'status' : event.value})}}/>
									</div>
									<div className="col-12 form-group">
										<label>Address</label>
										<Autocomplete
											apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
											onPlaceSelected={(place) => {
												this.setState({
													'address' : place.formatted_address,
													'latitude' : place.geometry.location.lat(),
													'longitude' : place.geometry.location.lng(),
												})
											}}
											options={{
												types: ["(regions)"],
												componentRestrictions: { country: "in" },
											}}
											defaultValue={this.state.address}
											style={{ width: "100%", borderStyle: "solid", borderColor: "#DDDDDD", borderRadius: "5px", height: "50px", padding: "10px" }}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" class="btn btn-danger" onClick={()=>{this.closeAddModel()}}>Close</button>
					<button type="submit" class="btn btn-success" onClick={()=>{this.addStringVendor()}}>Submit</button>
				</Modal.Footer>
			</Modal>
		)
	}

}

				
export default AddStringVendor