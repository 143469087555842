import React from "react";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css';
import withRouter from "../../../Services/withRouter";
import moment from "moment";
import Api from "../../../Services/api";


class StringServiceBooking extends React.Component {


    componentDidMount = async()=>{
        await this.getBooking()
    }

    getBooking = async() =>{
        let id = this.props.params.id;
        if(id){
			var response = await Api.GetRequest({}, 'string-service-booking/'+id);
            if(response.status == 200){
                // if(response.delivery_boy == null){
                //     response.delivery_boy = {
                //         'name'
                //     }
                // }

                // if(response.vendor == null){
                //     response.vendor = {}
                // }
                this.setState({
                    'booking' : response.booking
                })
            }
            else{
				toast.error(response.message);
                this.props.navigate('/string-service-bookings')
            }
		}
		else{
			toast.error("Invalid Booking ID");
			this.props.navigate('/string-service-bookings')
		}
    }



    constructor(props){
        super(props);
        this.state={
            "booking" : {
                'booking_string_items' : [],
                'booking_string_addons' : [],
                'vendor' : {},
                'delivery_boy':{}
            }
        }
    }


    render() {
        return (

            <>
                <div class="pcoded-main-container">
                    <div class="pcoded-content">
                        <div class="page-header">
                            <div class="page-block">
                                <div class="row align-items-center">
                                    <div class="col-md-12">
                                        <div class="page-header-title">
                                            <h5 class="m-b-10">Booking</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row invoice-contact">
                                                <div class="col-md-8">
                                                    <div class="invoice-box row">
                                                        <div class="col-sm-12">
                                                            <table class="table table-responsive invoice-table table-borderless p-l-20">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><a href="index.html" class="b-brand">
                                                                                <img class="img-fluid" src="assets/images/logo-dark.png" alt="Able pro Logo"/>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left">Company name </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left">1065 Mandan Road, Columbia MO, Missouri. (123)-65202</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left"><a class="text-secondary" href="mailto:demo@gmail.com" target="_top">demo@gmail.com</a></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left">+91 919-91-91-919</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4"></div>
                                            </div>
                                            <div class="card-body">
                                                <div class="row invoive-info">
                                                    <div class="col-md-3 col-xs-12 invoice-client-info">
                                                        <h6 className="text-left">Client Information :</h6>
                                                        <h6 className="m-0 text-left">{this.state.booking.name}</h6>
                                                        <h6 className="m-0 mt-2 text-left">{this.state.booking.mobile}</h6>
                                                        <p className="m-0 mt-2 text-left">{this.state.booking.address}</p>
                                                    </div>
                                                    <div class="col-md-3 col-sm-6">
                                                        <h6 className="text-left">Order Information :</h6>
                                                        <table class="table table-responsive invoice-table invoice-order table-borderless">
                                                            <tbody>
                                                                <tr>
                                                                    <th className="text-left">Date </th>
                                                                    <td className="text-left px-2">: &emsp;{moment(this.state.booking.created_at).format('lll')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="text-left">Slot Date </th>
                                                                    <td className="text-left px-2">: &emsp;{moment(this.state.booking.slot_date).format('ll')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="text-left">Slot Time </th>
                                                                    <td className="text-left px-2">
                                                                        {this.state.booking.slot_time == 0 &&
                                                                            <span>: &emsp;11:00 AM - 2:00 PM</span>
                                                                        }
                                                                        {this.state.booking.slot_time == 1 &&
                                                                            <span>: &emsp;7:00 PM - 9:00 PM</span>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                
                                                                <tr>
                                                                    <th className="text-left">Status</th>
                                                                    <td className="text-left px-2">
                                                                        :&emsp;
                                                                        {this.state.booking.status == 0 &&
                                                                           
                                                                            <span className="badge badge-warning">Pending</span>
                                                                        }
                                                                        {this.state.booking.status == 1 &&
                                                                            <span className="badge badge-info">Assigned To Vendor</span>
                                                                        }
                                                                        {this.state.booking.status == 2 &&
                                                                            <span className="badge badge-warning">Assigned To Delivery Boy</span>
                                                                        }
                                                                        {this.state.booking.status == 3 &&
                                                                            <span className="badge badge-info">Picked Up From Customer</span>
                                                                        }
                                                                        {this.state.booking.status == 4 &&
                                                                            <span className="badge badge-info">Delivered to Vendor</span>
                                                                        }
                                                                        {this.state.booking.status == 5 &&
                                                                            <span className="badge badge-info">Ready To Deliver</span>
                                                                        }
                                                                        {this.state.booking.status == 6 &&
                                                                            <span className="badge badge-success">Delivered</span>
                                                                        }
                                                                    </td>
                                                                </tr>



                                                               
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    
                                                    {this.state.booking.delivery_boy != null &&
                                                        <div class="col-md-3 col-sm-6">
                                                            <h6 className="text-left">Delivery Boy :</h6>
                                                            <table class="table table-responsive invoice-table invoice-order table-borderless">
                                                                <tbody>
                                                                    <tr>
                                                                        <th className="text-left">Name </th>
                                                                        <td className="text-left px-2">: &emsp;{this.state.booking.delivery_boy.name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Mobile </th>
                                                                        <td className="text-left px-2">: &emsp;{this.state.booking.delivery_boy.mobile}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    }
                                                    

                                                    {this.state.booking.vendor != null &&
                                                        <div class="col-md-3 col-sm-6">
                                                            <h6 className="text-left">Vendor Info :</h6>
                                                            <table class="table table-responsive invoice-table invoice-order table-borderless">
                                                                <tbody>
                                                                    <tr>
                                                                        <th className="text-left">Name </th>
                                                                        <td className="text-left px-2">: &emsp;{this.state.booking.vendor.name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Mobile </th>
                                                                        <td className="text-left px-2">: &emsp;{this.state.booking.vendor.mobile}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    }
                                                </div>
                                                   


                                                <h5 className="text-left py-4 px-4" style={{'backgroundColor' : '#E0E0E0'}}>String Items</h5>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="table-responsive">
                                                            <table class="table invoice-detail-table">
                                                                <thead>
                                                                    <tr class="thead-default">
                                                                        <th className="text-left">Racket Type</th>
                                                                        <th>String Type</th>
                                                                        <th>Color</th>
                                                                        <th>Tension</th>
                                                                        <th>Quantity</th>
                                                                        <th>String Price</th>
                                                                        <th>Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.booking.booking_string_items.map((item)=>{
                                                                        return(
                                                                            <tr>
                                                                                <td className="text-left">{item.racket_type}</td>
                                                                                <td>{item.string_type_name}</td>
                                                                                <td>{item.color}</td>
                                                                                <td>{item.tension}</td>
                                                                                <td>{item.quantity}</td>
                                                                                <td>₹ {item.price}</td>
                                                                                <td>₹ {item.price * item.quantity}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                


                                                <h5 className="text-left py-4 px-4" style={{'backgroundColor' : '#E0E0E0'}}>String Addons</h5>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="table-responsive">
                                                            <table class="table invoice-detail-table">
                                                                <thead>
                                                                    <tr class="thead-default">
                                                                        <th className="text-left">Image</th>
                                                                        <th>Name</th>
                                                                        <th>Color</th>
                                                                        <th>Size</th>
                                                                        <th>Quantity</th>
                                                                        <th>Discount (in %)</th>
                                                                        <th>Price</th>
                                                                        <th>Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.booking.booking_string_addons.map((item)=>{
                                                                        return(
                                                                            <tr>
                                                                                <td className="text-left">
                                                                                    <img src={process.env.REACT_APP_IMAGE_URL + '/media'+item.image} className="img img-fluid" style={{'width':'100px'}} />
                                                                                </td>
                                                                                <td>{item.name}</td>
                                                                                <td>{item.color}</td>
                                                                                <td>{item.size}</td>
                                                                                <td>{item.quantity}</td>
                                                                                <td>{item.discount} %</td>
                                                                                <td>₹ {item.price}</td>
                                                                                <td>₹ {item.net_amount}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                
                                                <div class="row">
                                                    <div className="col-6">
                                                        <table class="table table-responsive invoice-table invoice-total">
                                                            <tbody>
                                                                <tr>
                                                                    <th>Payment Mode :</th>
                                                                    {this.state.booking.payment_mode == 0 &&
                                                                        <td>Online</td>
                                                                    }
                                                                    {this.state.booking.payment_mode == 1 &&
                                                                        <td>COD</td>
                                                                    }
                                                                </tr>
                                                                <tr>
                                                                    <th>Payment Status :</th>
                                                                    {this.state.booking.payment_status == 0 &&
                                                                        <td>&emsp;Pending</td>
                                                                    }
                                                                    {this.state.booking.payment_status == 1 &&
                                                                        <td>&emsp;Complete</td>
                                                                    }
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="col-6">
                                                        <table class="table table-responsive invoice-table invoice-total">
                                                            <tbody>
                                                                <tr>
                                                                    <th>String Amount :</th>
                                                                    <td>&emsp;₹ {this.state.booking.string_amount}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Addon Amount :</th>
                                                                    <td>&emsp;₹ {this.state.booking.addon_amount}</td>
                                                                </tr>
                                                               
                                                                <tr class="text-info">
                                                                    <td>
                                                                        <hr />
                                                                        <h5 class="text-primary m-r-10">Total :</h5>
                                                                    </td>
                                                                    <td>
                                                                        <hr />
                                                                        <h5 class="text-primary">&emsp;₹ {this.state.booking.string_amount + this.state.booking.addon_amount}</h5>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}

export default withRouter(StringServiceBooking);

