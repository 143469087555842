import React from "react";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css';
import withRouter from "../../../Services/withRouter";
import moment from "moment";
import Api from "../../../Services/api";


class EcommerceOrder extends React.Component {


    componentDidMount = async()=>{
        await this.getOrder()
    }



    getOrder = async() =>{
        let id = this.props.params.id;
        if(id){
			var response = await Api.GetRequest({}, 'ecommerce/order/'+id);
            if(response.status == 200){
                
                if(response.delivery_boy == null){
                    response.delivery_boy = {}
                }
                this.setState({
                    'order' : response.order
                })
            }
            else{
				toast.error(response.message);
                this.props.navigate('/ecommerce/orders')
            }
		}
		else{
			toast.error("Invalid Booking ID");
			this.props.navigate('/ecommerce/orders')
		}
    }



    constructor(props){
        super(props);
        this.state={
            "order" : {
                'order_items' : [],
                'delivery_boy' : {}
            }
        }
    }


    render() {
        return (

            <>
                <div class="pcoded-main-container">
                    <div class="pcoded-content">
                        <div class="page-header">
                            <div class="page-block">
                                <div class="row align-items-center">
                                    <div class="col-md-12">
                                        <div class="page-header-title">
                                            <h5 class="m-b-10">Booking</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row invoice-contact">
                                                <div class="col-md-8">
                                                    <div class="invoice-box row">
                                                        <div class="col-sm-12">
                                                            <table class="table table-responsive invoice-table table-borderless p-l-20">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><a href="index.html" class="b-brand">
                                                                                <img class="img-fluid" src="assets/images/logo-dark.png" alt="Able pro Logo"/>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left">Company name </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left">1065 Mandan Road, Columbia MO, Missouri. (123)-65202</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left"><a class="text-secondary" href="mailto:demo@gmail.com" target="_top">demo@gmail.com</a></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left">+91 919-91-91-919</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4"></div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row invoive-info">
                                                <div class="col-md-4 col-xs-12 invoice-client-info">
                                                    <h6 className="text-left">Client Information :</h6>
                                                    <h6 className="m-0 text-left">{this.state.order.name}</h6>
                                                    <h6 className="m-0 mt-2 text-left">{this.state.order.mobile}</h6>
                                                    <p className="m-0 mt-2 text-left">{this.state.order.address}, {this.state.order.city}, {this.state.order.zipcode}</p>
                                                </div>
                                                <div class="col-md-4 col-sm-6">
                                                    <h6 className="text-left">Order Information :</h6>
                                                    <table class="table table-responsive invoice-table invoice-order table-borderless">
                                                        <tbody>
                                                            <tr>
                                                                <th className="text-left">Order Date </th>
                                                                <td className="text-left px-2">: &emsp;{moment(this.state.order.created_at).format('lll')}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-left">Payment Status </th>
                                                                {this.state.order.payment_status == 0 &&
                                                                    <td className="text-left px-2">: &emsp;Pending</td>
                                                                }
                                                                {this.state.order.payment_status == 1 &&
                                                                    <td className="text-left px-2">: &emsp;Paid</td>
                                                                }
                                                                
                                                            </tr>
                                                            <tr>
                                                                <th className="text-left">Order Status </th>
                                                                {this.state.order.status == 0 &&
                                                                    <td className="text-left px-2">: &emsp;Pending</td>
                                                                }
                                                                {this.state.order.status == 1 &&
                                                                    <td className="text-left px-2">: &emsp;Delivered</td>
                                                                }
                                                            </tr>
                                                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {this.state.order.delivery_boy != null &&
                                                    <div class="col-md-4 col-sm-6">
                                                        <h6 className="text-left">Delivery Boy :</h6>
                                                        <table class="table table-responsive invoice-table invoice-order table-borderless">
                                                            <tbody>
                                                                <tr>
                                                                    <th className="text-left">Name </th>
                                                                    <td className="text-left px-2">: &emsp;{this.state.order.delivery_boy.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="text-left">Mobile </th>
                                                                    <td className="text-left px-2">: &emsp;{this.state.order.delivery_boy.mobile}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </div>

                                            <h5 className="text-left py-4 px-4" style={{'backgroundColor' : '#E0E0E0'}}>Order Items</h5>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="table-responsive">
                                                        <table class="table invoice-detail-table">
                                                            <thead>
                                                                <tr class="thead-default">
                                                                    <th className="text-left">Image</th>
                                                                    <th>Name</th>
                                                                    <th>Color</th>
                                                                    <th>Size</th>
                                                                    <th>Quantity</th>
                                                                    <th>Discount (in %)</th>
                                                                    <th>Sub Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.order.order_items.map((item)=>{
                                                                    return(
                                                                        <tr>
                                                                            <td className="text-left">
                                                                                <img src={process.env.REACT_APP_IMAGE_URL + '/media'+item.image} className="img img-fluid" style={{'width':'100px'}} />
                                                                            </td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.color}</td>
                                                                            <td>{item.size}</td>
                                                                            <td>{item.quantity}</td>
                                                                            <td>{item.discount} %</td>
                                                                            <td>₹ {((item.price * item.quantity) - item.net_discount).toFixed(0)}</td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div className="col-6">
                                                    <table class="table table-responsive invoice-table invoice-total">
                                                        <tbody>
                                                            <tr>
                                                                <th>Payment Mode :</th>
                                                                {this.state.order.payment_mode == 0 &&
                                                                    <td>Online</td>
                                                                }
                                                                {this.state.order.payment_mode == 1 &&
                                                                    <td>COD</td>
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <th>Payment Status :</th>
                                                                {this.state.order.payment_status == 0 &&
                                                                    <td>&emsp;Pending</td>
                                                                }
                                                                {this.state.order.payment_status == 1 &&
                                                                    <td>&emsp;Complete</td>
                                                                }
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div class="col-6">
                                                    <table class="table table-responsive invoice-table invoice-total">
                                                        <tbody>
                                                            <tr>
                                                                <th>MRP Total :</th>
                                                                <td>&emsp;₹ {this.state.order.total_price}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Discount :</th>
                                                                <td>&emsp;-₹ {this.state.order.total_discount}</td>
                                                            </tr>
                                                            
                                                            <tr class="text-info">
                                                                <td>
                                                                    <hr />
                                                                    <h5 class="text-primary m-r-10">Total :</h5>
                                                                </td>
                                                                <td>
                                                                    <hr />
                                                                    <h5 class="text-primary">&emsp;₹  {this.state.order.total_price - this.state.order.total_discount}</h5>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}

export default withRouter(EcommerceOrder);

