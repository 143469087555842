import React from "react";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

import Api from "../../../Services/api";

class NewSubCategory extends React.Component{

	status_list = [
		{'value': 0, 'label': 'In Active'},
		{'value': 1, 'label': 'Active'},
	]

	
	componentDidMount = async()=>{
		this.resetState();
	}


	resetState = async()=>{
		this.setState({
			'name' : "",
			'status' : "",
			'image' : {}
		});
	}


	constructor(props){
		super(props);
		this.state={
			'name' : "",
			'status' : "",
			'image' : {}
		}
	}


	closeAddModel = async()=>{
		this.props.closeAddModel();
	}



	addSubCategory = async()=>{
		var form_data = new FormData()
		form_data.append('name', this.state.name.trim());
		form_data.append('status', this.state.status);
		form_data.append('image', this.state.image);
		var response = await Api.PostRequestForm(form_data, 'ecommerce/sub-categories');
		if(response.status == 200){
			toast.success(response.message);
			this.props.closeAddModel();
		}
		else{
			toast.error(response.message);
		}
	}

	render(){
		return(
			<Modal show={this.props.show_add_model} onHide={() => this.closeAddModel()}>
				<Modal.Header>
					<h5>New Sub Category</h5>
				</Modal.Header>
				<Modal.Body>
					<div class="reservation-page-section">
						<div class="container">
							<div class="reservation-detais form-inner">
								<div class="row form-wrap">
									<div className="col-12 form-group">
										<label>Name</label>
										<input className="form-control" value={this.state.name} onChange={(event) => {this.setState({'name': event.target.value})}} type="text" placeholder=""/>
									</div>
									<div class="col-12 form-group">
										<label>Status</label>
										<Select options={this.status_list} onChange={(event)=>{this.setState({'status' : event.value})}}/>
									</div>

									<div className="col-12 form-group">
										<label className="text-left w-100">Image</label>
										<input className="form-control" onChange={(event)=>{this.setState({'image' : event.target.files[0]});}}  type="file" placeholder=""/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" class="btn btn-danger" onClick={()=>{this.closeAddModel()}}>Close</button>
					<button type="submit" class="btn btn-success" onClick={()=>{this.addSubCategory()}}>Submit</button>
				</Modal.Footer>
			</Modal>
		)
	}

}

				
export default NewSubCategory