import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';

import AddStringVendor from "./AddStringVendor";
import EditStringVendor from "./EditStringVendor";

class StringVendors extends Component {

	componentDidMount=()=>{
		this.getStringVendors();
	}


	getStringVendors = async()=>{
		var response = await Api.GetRequest({}, "string-vendors");
		if(response.status == 200){
			this.setState({'string_vendors' : response.string_vendors});
		}
		else{

		}
	}


	constructor(props) {
		super(props);
		this.state = {
			'show_add_model': false,
            'show_edit_model': false,
			'string_vendors' : [],
            'string_vendor' : {
                'id' : "",
                'name' : "",
                'mobile' : "",
				'email'  : "",
                'status' : "",
				'address' : "",
				'latitude' : null,
				'longitude' : null
            }
		};
	}


	closeAddModel = async()=>{
		this.setState({'show_add_model' : false});
		await this.getStringVendors();
	}



	openEditModel = async(string_vendor)=>{
		var string_vendor = {
			'id' : string_vendor.id,
			'name' : string_vendor.name,
			'status' : string_vendor.status,
			'email'  : string_vendor.email,
            'mobile' : string_vendor.mobile,
			'address' : string_vendor.address,
			'latitude' : string_vendor.latitude,
			'longitude' : string_vendor.longitude
		}

		this.setState({
			'string_vendor' : string_vendor,
			'show_edit_model' : true
		});
	}


	closeEditModel = async()=>{
		this.setState({'show_edit_model' : false});
		await this.getStringVendors();
	}


	deleteStringVendor = async(string_id)=>{
		var response = await Api.DeleteRequest('string-vendor/'+string_id);
		if(response.status == 200){
			toast.success(response.message);
			this.getStringVendors();
		}
		else{
			toast.error(response.message);
		}
	}

	render() {
		return (
			<>
				<AddStringVendor  show_add_model={this.state.show_add_model} closeAddModel={this.closeAddModel}/>
				<EditStringVendor  show_edit_model={this.state.show_edit_model} string_vendor={this.state.string_vendor} closeEditModel={this.closeEditModel}/>

				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">String Vendors</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">String Vendors</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										<button type="button" class="btn btn-success" onClick={()=>{this.setState({'show_add_model' :  true})}}>
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> String Vendor
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
									<div class="dt-responsive table-responsive">
										<table id="full-row-reorder" class="table table-striped table-bordered nowrap">
											<thead>
												<tr>
													<th>S. No.</th>
													<th>Name</th>
													<th>Email</th>
                                                    <th>Mobile</th>
													<th>Status</th>
													<th>Action</th>
												</tr>
											</thead>
											
											<tbody>
												{this.state.string_vendors.map((string_vendor, index)=>{
													return(
														<tr>
															<td>{index + 1}</td>
															<td>{string_vendor.name}</td>
															<td>{string_vendor.email}</td>
                                                            <td>{string_vendor.mobile}</td>
															<td>
																{string_vendor.status == 0 &&
																	<span className="badge badge-danger">In active</span>
																}
																{string_vendor.status == 1 &&
																	<span className="badge badge-success">Active</span>
																}
															</td>
															<td>
																<button onClick={()=>{this.openEditModel(string_vendor)}} className="btn btn-info btn-sm mx-2"><i className="feather icon-edit"></i>&nbsp;Edit </button>
                                            					<button onClick={()=>{this.deleteStringVendor(string_vendor.id)}} className="btn btn-danger btn-sm mx-2"><i className="feather icon-trash-2"></i>&nbsp;Delete </button>
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default StringVendors;
