import React from "react";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css';
import withRouter from "../../../Services/withRouter";

import Api from "../../../Services/api";


class EditStringType extends React.Component {


    status_list = [
		{'value': 0, 'label': 'In Active'},
		{'value': 1, 'label': 'Active'},
	]


	string_types = [
		{'value': 0, 'label': 'Badminton'},
		{'value': 1, 'label': 'Tennis'},
	]
	
	componentDidMount = async()=>{
		await this.getLocations();
		this.getStringType();
	}



    getLocations = async() =>{
		var response = await Api.GetRequest({}, 'locations-list');
		if(response.status == 200){
			this.setState({'locations_list': response.locations})
		}
		else{
			toast.success(response.message);
		}
	}



	getStringType = async() =>{
        let id = this.props.params.id;
        if(id){
			var response = await Api.GetRequest({}, 'string-type/'+id);
            if(response.status == 200){
               this.setState({
					'name' : response.string_type.name,
					'status' : response.string_type.status,
					'string_type' : response.string_type.string_type,
					'variants' : response.string_type.string_variants,
					'locations' : response.string_type.string_locations,
					'price' : response.string_type.price,
					'description' : response.string_type.description,
					'id' : id
			   })
            }
            else{
				toast.error(response.message);
                this.props.navigate('/string-types')
            }
		}
		else{
			toast.error(response.message);
			this.props.navigate('/string-types')
		}
    }



	

	constructor(props){
		super(props);
		this.state={
			'name' : "",
			'status' : "",
			'string_type' : "",
			'price' : "",
			'image' : {},
			'locations_list' : [],
			'locations' : [],
			'variants' : [{'color':"", 'quantity' : ""}]
		}
	}



    addStringType = async()=>{
		var form_data = new FormData()
		form_data.append('name', this.state.name.trim());
		form_data.append('status', this.state.status);
		form_data.append('price', this.state.price);
		form_data.append('string_type', this.state.string_type);
		form_data.append('description', this.state.description);
		if(this.state.image){
            form_data.append('image', this.state.image);
        }
		form_data.append('variants', JSON.stringify(this.state.variants));
		await this.state.locations.map(async(location)=>{
			form_data.append('locations', location.value)	
		});

		var response = await Api.PostRequestForm(form_data, 'string-type/'+this.state.id);
		if(response.status == 200){
			toast.success(response.message);
			this.props.navigate('/string-types')
		}
		else{
			toast.error(response.message);
		}
	}



	setVariant = async(index, section, value)=>{
        var variants = this.state.variants;
        if(section == 0){
            variants[index].color = value;
        }
        else{
            variants[index].quantity = value;   
        }
        this.setState({'variants' : variants});
    }


    deleteVariant = async(index) =>{
        var variants = this.state.variants;
        variants.splice(index, 1);
        this.setState({'variants' : variants});
    }


    addVariant = async()=>{
        var variants = this.state.variants;
        await variants.push({'color':"", 'quantity' : ""});
        await this.setState({'variants' : variants});
    }




	render() {
		return (

			<>
				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-12">
										<div class="page-header-title">
											<h5 className="m-b-10 text-left">String type</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">String Type</a></li>
											<li class="breadcrumb-item"><a href="#!">New String Type</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
                                        <div class="row form-wrap">
                                            <div className="col-6 form-group">
                                                <label className="text-left" style={{'width' : '100%'}}>Name</label>
                                                <input className="form-control" value={this.state.name} onChange={(event) => {this.setState({'name': event.target.value})}} type="text" placeholder=""/>
                                            </div>
                                            
                                            <div class="col-6 form-group">
                                                <label className="text-left" style={{'width' : '100%'}}>Status</label>
                                                <Select value={this.status_list[this.state.status]} options={this.status_list} onChange={(event)=>{this.setState({'status' : event.value})}}/>
                                            </div>

											<div class="col-6 form-group">
                                                <label className="text-left" style={{'width' : '100%'}}>String Type</label>
                                                <Select options={this.string_types} value={this.string_types[this.state.string_type]} onChange={(event)=>{this.setState({'string_type' : event.value})}}/>
                                            </div>

                                            <div class="col-6 form-group">
                                                <label className="text-left" style={{'width' : '100%'}}>Price</label>
                                                <input className="form-control" value={this.state.price} onChange={(event) => {this.setState({'price': event.target.value})}} type="number" placeholder=""/>
                                            </div>


                                            <div className="col-6 form-group">
                                                <label className="text-left" style={{'width' : '100%'}}>Image</label>
                                                <input className="form-control" onChange={(event)=>{this.setState({'image' : event.target.files[0]});}}  type="file" placeholder=""/>
                                            </div>


                                            <div class="col-12 py-3 mt-3 justify-content-between d-flex align-items-center flex-row" style={{"backgroundColor": "#EDEDED"}}>
                                                <h5 class="my-0">Locations</h5>
                                            </div>

                                            <div class="col-12 mt-4 form-group">
                                                <Select options={this.state.locations_list} value={this.state.locations} onChange={(event)=>{this.setState({'locations' : event})}} isMulti={true}/>
                                            </div>

                                            
                                            <div class="col-12 py-3 mt-3 justify-content-between d-flex align-items-center flex-row" style={{"backgroundColor": "#EDEDED"}}>
                                                <h5 class="my-0">Variants</h5>
                                            </div>

                                            <div class="col-12 mt-4">
                                                <table class="table w-50 table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Color</th>
                                                            <th>Quantity</th>
                                                            <th><button onClick={()=>{this.addVariant()}} type="button" id="add-variant" class="btn btn-primary py-1 my-0" >Add</button></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="variant-container">
                                                        {this.state.variants.map((variant, index)=>{
                                                            return(
                                                                <tr>
                                                                    <td>
                                                                        <input className="form-control" value={variant.color} onChange={(event) => {this.setVariant(index, 0, event.target.value)}} type="text" placeholder=""/>
                                                                    </td>
                                                                    <td>
                                                                        <input className="form-control" value={variant.quantity} onChange={(event) => {this.setVariant(index, 1, event.target.value)}} type="number" placeholder=""/>
                                                                    </td>
                                                                    <td>
                                                                        <i class="fa fa-trash delete-variant" onClick={()=>{this.deleteVariant(index)}} aria-hidden="true" style={{'color':"red"}}></i>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        
                                                    </tbody>
                                                </table>
                                            </div>


											<div class="col-12 py-3 mt-3 justify-content-between d-flex align-items-center flex-row" style={{"backgroundColor": "#EDEDED"}}>
                                                <h5 class="my-0">Description</h5>
                                            </div>

                                            <div className="col-12 form-group">
                                                <textarea className="form-control mt-3" value={this.state.description} onChange={(event) => {this.setState({'description': event.target.value})}} type="text" placeholder=""></textarea>
                                            </div>

											<div className="col-12 ">
                                                <button type="submit" class="btn btn-success" onClick={()=>{this.addStringType()}}>Submit</button>
                                            </div>
                                        </div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>

		)
	}
}

export default withRouter(EditStringType);

