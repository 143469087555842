import React, { Component } from "react";

import { Link } from "react-router-dom";


import { AuthContext } from "../../utils/AuthContext";

export class Header extends Component {

	static contextType = AuthContext;

	componentDidMount = async () => {
		this.setState({
			'active_section' : 0,
			'active_menu' : 0,
			'active_submenu' : 0,
		});
	};



	routes = [
		{
			name: "GENERAL",
			menus: [
				{
					name: "Dashboard",
					link: "/dashboard",
					icon: "feather icon-home",
					active: true,
					submenus: [],
				},
				{
					name: "Profile",
					link: "/profile",
					icon: "feather icon-user",
					active: false,
					submenus: [],
				},
				{
					name: "Banners",
					link: "/banners",
					icon: "feather icon-home",
					active: false,
					submenus: [],
				},
			],
		},
		{
			name: "String Service",
			menus: [
				{
					name: "String Types",
					link: "string-types",
					icon: "fas fa-copyright",
					active: false,
					submenus: [],
				},
				{
					name: "String Vendors",
					link: "string-vendors",
					icon: "fas fa-copyright",
					active: false,
					submenus: [],
				},
				{
					name: "String Bookings",
					link: "string-service-bookings",
					icon: "fas fa-copyright",
					active: false,
					submenus: [],
				},
				{
					name: "String Addons",
					link: "string-addons",
					icon: "fas fa-copyright",
					active: false,
					submenus: [],
				},
			],
		},
		{
			name: "Ecommerce",
			menus: [
				{
					name: "Category",
					link: "ecommerce/categories",
					icon: "fas fa-copyright",
					active: false,
					submenus: [],
				},
				{
					name: "Sub Category",
					link: "ecommerce/sub-categories",
					icon: "fas fa-copyright",
					active: false,
					submenus: [],
				},
				{
					name: "Product",
					link: "ecommerce/products",
					icon: "fas fa-copyright",
					active: false,
					submenus: [],
				},
				{
					name: "Orders",
					link: "ecommerce/orders",
					icon: "fas fa-copyright",
					active: false,
					submenus: [],
				},
			],
		},
		{
			name: "Stadiums",
			menus: [
				{
					name: "Sports",
					link: "stadiums/sports",
					icon: "fas fa-map",
					active: false,
					submenus: [],
				},
				{
					name: "Stadiums",
					link: "stadiums/stadiums",
					icon: "fas fa-map",
					active: false,
					submenus: [],
				}
			],
		},
		{
			name: "Delivery Boys",
			menus: [
				{
					name: "Delivery Boys",
					link: "delivery-boys",
					icon: "fas fa-map",
					active: false,
					submenus: [],
				}
			],
		},
		{
			name: "Settings",
			menus: [
				{
					name: "Locations",
					link: "locations",
					icon: "fas fa-map",
					active: false,
					submenus: [],
				},
				{
					name: "Coupons",
					link: "coupons",
					icon: "fas fa-map",
					active: false,
					submenus: [],
				}
			],
		}
	];




	constructor(props) {
		super(props);
		this.state = {
			'active_section': 0,
			'active_menu': 0,
			'active_submenu': 0,

			sections: this.routes,
		};
	}

	activateLink = async (section_index, menu_index, submenu_index) => {
		// e.preventDefault();
		var sections = await this.state.sections;

		if (this.state.active_submenu != "") {
			sections[this.state.active_section].menus[this.state.active_menu].submenus[this.state.active_submenu].active = false;
		}

		sections[this.state.active_section].menus[this.state.active_menu].active = false;

		if (submenu_index != "") {
			sections[section_index].menus[menu_index].submenus[submenu_index].active = !sections[section_index].menus[menu_index].submenus[submenu_index].active;
		}

		sections[section_index].menus[menu_index].active = !sections[section_index].menus[menu_index].active;

		this.setState({
			active_section: section_index,
			active_menu: menu_index,
			active_submenu: submenu_index,
			sections: sections,
		});
	};

	logout = async () => {
		await localStorage.removeItem("token");
		this.context.signOut()
	};

	render() {
		return (
			<>
				<nav className="pcoded-navbar menu-light">
					<div className="navbar-wrapper" style={{ overflow: "auto" }}>
						<div className="navbar-content scroll-div ">
							<div className="">
								<div className="main-menu-header">
									<img className="img-radius" src="/assets/images/user/avatar-2.jpg" alt="User-Profile-Image" />
									<div className="user-details">
										<div id="more-details">
											UX Designer <i className="fa fa-caret-down"></i>
										</div>
									</div>
								</div>
								<div className="collapse" id="nav-user-Link">
									<ul className="list-inline">
										<li className="list-inline-item">
											<a href="user-profile.html" data-toggle="tooltip" title="View Profile">
												<i className="feather icon-user"></i>
											</a>
										</li>
										<li className="list-inline-item">
											<a href="email_inbox.html">
												<i className="feather icon-mail" data-toggle="tooltip" title="Messages"></i>
												<small className="badge badge-pill badge-primary">5</small>
											</a>
										</li>
										<li className="list-inline-item">
											<a href="auth-signin.html" data-toggle="tooltip" title="Logout" className="text-danger">
												<i className="feather icon-power"></i>
											</a>
										</li>
									</ul>
								</div>
							</div>

							<ul className="nav pcoded-inner-navbar">
								{this.state.sections.map((section, section_index) => {
									return (
										<>
											<li className="nav-item pcoded-menu-caption" style={{ textAlign: "left", paddingLeft: "10px" }}>
												<label>{section.name}</label>
											</li>

											{section.menus.map((menu, menu_index) => {
												return (
													<>
														{menu.submenus.length == 0 && (
															<li className={"nav-item " + (menu.active == true ? "active" : "")}>
																<Link
																	to={`${menu.link}`}
																	onClick={() => {
																		this.activateLink(section_index, menu_index, "");
																	}}
																	className="nav-link"
																>
																	<span className="pcoded-micon">
																		<i class={`${menu.icon}`}></i>
																	</span>
																	<span className="pcoded-mtext">{menu.name}</span>
																</Link>
															</li>
														)}
														{menu.submenus.length > 0 && (
															<li class={"nav-item pcoded-hasmenu " + (menu.active == true ? "pcoded-trigger" : "")}>
																<a
																	href="javascript:;"
																	class="nav-link"
																	onClick={() => {
																		this.activateLink(section_index, menu_index, "");
																	}}
																>
																	<span class="pcoded-micon">
																		<i class={`${menu.icon}`}></i>
																	</span>
																	<span class="pcoded-mtext">{menu.name}</span>
																</a>
																{menu.active == true && (
																	<>
																		<ul className="pcoded-submenu" style={{ display: "block" }}>
																			{menu.submenus.map((submenu, submenu_index) => {
																				return (
																					<li>
																						<Link
																							to={menu.link + "/" + submenu.link}
																							onClick={() => {
																								this.activateLink(section_index, menu_index, submenu_index);
																							}}
																						>
																							{submenu.name}
																						</Link>
																					</li>
																				);
																			})}
																		</ul>
																	</>
																)}
															</li>
														)}
													</>
												);
											})}
										</>
									);
								})}
								<li className={"nav-item " + (this.state.active_section == 1 ? "active" : "")}>
									<Link
										to={"/dashboard"}
										onClick={() => {
											this.activateLink(0, 0, "");
										}}
										className="nav-link"
									>
										<span className="pcoded-micon">
											<i className="feather icon-home"></i>
										</span>
										<span className="pcoded-mtext">Dashboard</span>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</nav>
				<header className="navbar pcoded-header navbar-expand-lg navbar-light header-blue">
					<div className="m-header">
						<a className="mobile-menu" id="mobile-collapse" href="#!">
							<span></span>
						</a>
						<a href="#!" className="b-brand">
							<img src="assets/images/logo.png" alt="" className="logo" />
						</a>
						<a href="#!" className="mob-toggler">
							<i className="feather icon-more-vertical"></i>
						</a>
					</div>
					<div className="collapse navbar-collapse">
						<ul className="navbar-nav ml-auto">
							<li></li>
							<li>
								<div className="dropdown drp-user">
									<a
										href="javascript:;"
										onClick={() => {
											this.logout();
										}}
										className="dropdown-toggle"
										data-toggle="dropdown"
									>
										<i className="feather icon-log-out"></i>
									</a>
								</div>
							</li>
						</ul>
					</div>
				</header>
			</>
		);
	}
}

export default Header;
