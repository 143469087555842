import React from "react";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import Api from "../../Services/api";

class EditDeliveryBoy extends React.Component{

	status_list = [
		{'value': 0, 'label': 'In Active'},
		{'value': 1, 'label': 'Active'},
	]


	componentDidMount = async()=>{
		this.resetState();
	}


	resetState = async()=>{
		this.setState({
			'name' : "",
			'status' : "",
			'mobile' : "",
            'id' : ""
		});
	}


	constructor(props){
		super(props);
		this.state={
			'name' : "",
			'status' : "",
			'mobile' : "",
            'id' : ""
		}
	}


	closeEditModel = async()=>{
		this.props.closeEditModel();
	}


	updateDeliveryBoy = async()=>{
		var data = {
			'name' : this.state.name.trim(),
			'status' : this.state.status,
            'mobile' : this.state.mobile
		}
		var response = await Api.PutRequest(data, 'delivery-boy/'+this.state.id);
		if(response.status == 200){
			toast.success(response.message);
			this.props.closeEditModel();
		}
		else{
			toast.error(response.message);
		}
	}


    componentWillReceiveProps = async(nextProps)=>{
	
        this.setState({
            'id' : nextProps.delivery_boy.id,
            'name' : nextProps.delivery_boy.name,
            'status' : nextProps.delivery_boy.status,
            'mobile' : nextProps.delivery_boy.mobile
        });
    }

	render(){
		return(
			<Modal show={this.props.show_edit_model} onHide={() => this.closeEditModel()}>
				<Modal.Header>
					<h5>Edit Delivery Boy</h5>
				</Modal.Header>
				<Modal.Body>
					<div class="reservation-page-section">
						<div class="container">
							<div class="reservation-detais form-inner">
								<div class="row form-wrap">
									<div className="col-12 form-group">
										<label>Name</label>
										<input className="form-control" value={this.state.name} onChange={(event) => {this.setState({'name': event.target.value})}} type="text" placeholder=""/>
									</div>
                                    <div className="col-12 form-group">
										<label>Mobile</label>
										<input className="form-control" value={this.state.mobile} onChange={(event) => {this.setState({'mobile': event.target.value})}} type="text" placeholder=""/>
									</div>
									<div class="col-12 form-group">
										<label>Status</label>
										<Select value={this.status_list[this.state.status]} options={this.status_list} onChange={(event)=>{this.setState({'status' : event.value})}}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" class="btn btn-danger" onClick={()=>{this.closeEditModel()}}>Close</button>
					<button type="submit" class="btn btn-success" onClick={()=>{this.updateDeliveryBoy()}}>Submit</button>
				</Modal.Footer>
			</Modal>
		)
	}

}

				
export default EditDeliveryBoy