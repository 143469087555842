import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';


class StringTypes extends Component {

	componentDidMount=()=>{
		this.getStringTypes();
	}


	getStringTypes = async()=>{
		var response = await Api.GetRequest({}, "string-types");
		if(response.status == 200){
			this.setState({'string_types' : response.string_types});
		}
		else{

		}
	}


	constructor(props) {
		super(props);
		this.state = {
			'show_add_model': false,
            'show_edit_model': false,
			'string_types' : [],
            'string_type' : {
                'id' : "",
                'name' : "",
                'status' : "",
				'string_type' : "",
				'price' : "",
				'image' : {},
				'description'  : "",
				'colors' : [],
				'quantity' : 0,
            }
		};
	}



	deleteStringType = async(string_id)=>{
		var response = await Api.DeleteRequest('string-type/'+string_id);
		if(response.status == 200){
			toast.success(response.message);
			this.getStringTypes();
		}
		else{
			toast.error(response.message);
		}
	}

	render() {
		return (
			<>
				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">String Types</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">String types</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										<Link type="button" class="btn btn-success" to={'/string-types/new-string-type'}>
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> String Type
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
									<div class="dt-responsive table-responsive">
										<table id="full-row-reorder" class="table table-striped table-bordered nowrap">
											<thead>
												<tr>
													<th>S. No.</th>
													<th>Name</th>
													<th>Image</th>
													<th>Price</th>
													<th>String For</th>
													<th>Status</th>
													<th>Created At</th>
													<th>Action</th>
												</tr>
											</thead>
											
											<tbody>
												{this.state.string_types.map((string_type, index)=>{
													return(
														<tr>
															<td>{index + 1}</td>
															<td>{string_type.name}</td>
															<td><img src={process.env.REACT_APP_IMAGE_URL + string_type.image} className="img img-fluid" style={{'width':'100px'}} /></td>
															<td className="text-right">{string_type.price}</td>
															<td>
																{string_type.status == 0 &&
																	<span className="badge badge-danger">In active</span>
																}
																{string_type.status == 1 &&
																	<span className="badge badge-success">Active</span>
																}
															</td>
															<td>
																{string_type.string_type == 0 &&
																	<span>Badminton</span>
																}
																{string_type.string_type == 1 &&
																	<span>Tennis</span>
																}
															</td>
															<td>{moment(string_type.created_at).format('lll')}</td>
															<td>
																<Link to={`/string-types/edit-string-type/${string_type.id}`} className="btn btn-info btn-sm mx-2"><i className="feather icon-edit"></i>&nbsp;Edit </Link>
                                            					<button onClick={()=>{this.deleteStringType(string_type.id)}} className="btn btn-danger btn-sm mx-2"><i className="feather icon-trash-2"></i>&nbsp;Delete </button>
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default StringTypes;
