import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';
import DataTable from "react-data-table-component";

class Products extends Component {

	componentDidMount=()=>{
		this.getProducts();
	}


	getProducts = async()=>{
		var response = await Api.GetRequest({}, "ecommerce/products");
		if(response.status == 200){
			this.setState({'products' : response.products, 'filtered_products' : response.products});
		}
		else{

		}
	}


	constructor(props) {
		super(props);
		this.state = {
			'products' : [],
			'filter_text' : "",
			'filtered_products': []
		};
	}



	deleteProduct = async(category_id)=>{
		var response = await Api.DeleteRequest('ecommerce/product/'+category_id);
		if(response.status == 200){
			toast.success(response.message);
			this.getProducts();
		}
		else{
			toast.error(response.message);
		}
	}


	handleClear = () => {
        if (this.state.filter_text) {
			this.setState({
				filter_text: ""
			});
        }
	};




	searchProducts = async(searched_text)=>{
		var filtered_products = this.state.products.filter(
			(item) =>
			  item.name &&
			  item.name.toLowerCase().includes(searched_text.toLowerCase())
		);
		this.setState({'searched_text' : searched_text, 'filtered_products' : filtered_products});
	}



    getSubHeaderComponent = () => {
        return (
			<div class="form-group mb-4">
				<input type="search" class="form-control" id="search" placeholder="Search here..." value={this.state.searched_text} onChange={(event) => this.searchProducts(event.target.value)} />
			</div>
        );
      };



	columns = [
		{
			name: "S. No.",
			selector: (row, index) => index + 1,
			sortable: true,
		},
		{
			name: "Name",
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: "Image",
			selector: (row) => (<img src={process.env.REACT_APP_IMAGE_URL + row.image} className="img img-fluid my-3" style={{'width':'100px'}} />),
			sortable: true,
		},
		{
			name: "Category",
			selector: (row) => (row.category.label),
			sortable: true,
		},
		{
			name: "Sub Category",
			selector: (row) => (row.sub_category.label),
			sortable: true,
		},
		{
			name: "Price",
			selector: (row) => `₹ ${row.price}`,
			sortable: true,
			right: true,
		},
		{
			name: "Order",
			selector: (row) => (row.order_no),
			sortable: true,
		},
		{
			name: "Stock",
			selector: (row) => (
				<>
					{row.stock == false &&
						<span className="badge badge-danger">Not available</span>
					}
					{row.stock == true &&
						<span className="badge badge-success">Available</span>
					}
				</>
			),
			sortable: true,
			right: true,
		},
		{
			name: "Status",
			selector: (row) => (
				<>
					{row.status == 0 &&
						<span className="badge badge-danger">In active</span>
					}
					{row.status == 1 &&
						<span className="badge badge-success">Active</span>
					}
				</>
			),
			sortable: true,
			right: true,
		},
		{
			name: "Popular",
			selector: (row) => (
				<>
					{row.popular == 0 &&
						<span className="badge badge-danger">In active</span>
					}
					{row.popular == 1 &&
						<span className="badge badge-success">Active</span>
					}
				</>
			),
			sortable: true,
			right: true,
		},
		{
			name: "Latest Arrival",
			selector: (row) => (
				<>
					{row.latest_arrival == 0 &&
						<span className="badge badge-danger">In active</span>
					}
					{row.latest_arrival == 1 &&
						<span className="badge badge-success">Active</span>
					}
				</>
			),
			sortable: true,
			right: true,
		},
		{
			name: "Addon For",
			selector: (row) => (
				<>
					{row.addon_for == 0 &&
						<span>Badminton</span>
					}
					{row.addon_for == 1 &&
						<span>Tennis</span>
					}
					{row.addon_for == 2 &&
						<span>Badminton, Tennis</span>
					}
					{row.addon_for == 3 &&
						<span>None</span>
					}
				</>

			),
			sortable: true,
			right: true,
		},
		// {
		// 	name: "Created At",
		// 	selector: (row) => `₹ ${moment(row.created_at).format('lll')}`,
		// 	sortable: true,
		// 	right: true,
		// },
		{
			name: "",
			cell: (row) => (
				<Link to={`/ecommerce/edit-product/${row.id}`} className="btn btn-info btn-sm mx-2"><i className="feather icon-edit"></i>&nbsp;Edit </Link>
			),
		},
		{
			name: "",
			cell: (row) => (
				<button onClick={()=>{this.deleteProduct(row.id)}} className="btn btn-danger btn-sm mx-2"><i className="feather icon-trash-2"></i>&nbsp;Delete </button>
			),
		},
	];



	render() {
		return (
			<>
				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">Ecommerce</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">Products</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										<Link to="/ecommerce/new-product" type="button" class="btn btn-success">
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> Products
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
										<DataTable
											title=""
											fixedHeader
											columns={this.columns}
											data={this.state.filtered_products}
											pagination
											striped
											compact = {0}
											dense = {false}
											subHeader = {true}
											subHeaderComponent={this.getSubHeaderComponent()}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Products;
