import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';
import DataTable from "react-data-table-component";

class Stadiums extends Component {

	componentDidMount=()=>{
		this.getStadiums();
	}


	getStadiums = async()=>{
		var response = await Api.GetRequest({}, "stadiums/stadiums");
		if(response.status == 200){
			this.setState({'stadiums' : response.stadiums});
		}
		else{

		}
	}


	constructor(props) {
		super(props);
		this.state = {
			'stadiums' : []
		};
	}



	deleteStadium = async(id)=>{
		var response = await Api.DeleteRequest('stadiums/stadium/'+id);
		if(response.status == 200){
			toast.success(response.message);
			this.getStadiums();
		}
		else{
			toast.error(response.message);
		}
	}



   

	columns = [
		{
			name: "S. No.",
			selector: (row, index) => index + 1,
			sortable: true,
		},
		{
			name: "Stadium Name",
			selector: (row) => row.stadium_name,
			sortable: true,
		},
        // {
		// 	name: "Image",
		// 	selector: (row) => (<img src={process.env.REACT_APP_IMAGE_URL + row.image} className="img img-fluid my-3" style={{'width':'100px'}} />),
		// 	sortable: true,
		// },
        {
			name: "Owner Name",
			selector: (row) => (row.name),
			sortable: true,
		},
        {
			name: "Email",
			selector: (row) => (row.email),
			sortable: true,
		},
        {
			name: "Mobile",
			selector: (row) => (row.mobile),
			sortable: true,
		},
		{
			name: "Status",
			selector: (row) => (
				<>
					{row.status == 0 &&
						<span className="badge badge-danger">In active</span>
					}
					{row.status == 1 &&
						<span className="badge badge-success">Active</span>
					}
				</>
			),
			sortable: true,
			right: true,
		},
		{
			name: "",
			cell: (row) => (
				<Link to={`/stadiums/edit-stadium/${row.id}`} className="btn btn-info btn-sm mx-2"><i className="feather icon-edit"></i>&nbsp;Edit </Link>
			),
		},
		{
			name: "",
			cell: (row) => (
				<button onClick={()=>{this.deleteStadium(row.id)}} className="btn btn-danger btn-sm mx-2"><i className="feather icon-trash-2"></i>&nbsp;Delete </button>
			),
		},
	];



	render() {
		return (
			<>
				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">Ecommerce</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">Stadiums</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										<Link to="/stadiums/new-stadium" type="button" class="btn btn-success">
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> Stadium
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
										<DataTable
											title=""
											fixedHeader
											columns={this.columns}
											data={this.state.stadiums}
											pagination
											striped
											compact = {0}
											dense = {false}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Stadiums;
