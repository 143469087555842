import React from "react";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import Api from "../../../Services/api";
import withRouter from "../../../Services/withRouter";

class NewProduct extends React.Component{

	status_list = [
		{'value': 0, 'label': 'In Active'},
		{'value': 1, 'label': 'Active'},
	]


    addon_for = [
		{'value': 0, 'label': 'Badminton'},
		{'value': 1, 'label': 'Tennis'},
        {'value': 2, 'label': 'Both'},
        {'value': 3, 'label': 'None'},
	]

	
	componentDidMount = async()=>{
        this.getCategories();
        this.getSubCategories();
        this.getLocations();
	}



    getLocations = async() =>{
		var response = await Api.GetRequest({}, 'locations-list');
		if(response.status == 200){
			this.setState({'locations_list': response.locations})
		}
		else{
			toast.success(response.message);
		}
	}


    getCategories = async() =>{
        var response = await Api.GetRequest({}, 'ecommerce/categories/list');
        if(response.status == 200){
            this.setState({'categories' : response.categories});
        }
        else{
            toast.error(response.message);
        }
    }


    getSubCategories = async()=>{
        var response = await Api.GetRequest({}, 'ecommerce/sub-categories/list');
        if(response.status == 200){
            this.setState({'sub_categories' : response.sub_categories});
        }
        else{
            toast.error(response.message);
        }
    }



	constructor(props){
		super(props);
		this.state={
			'categories' : [],
            'sub_categories' : [],
            'locations' : [],
			'locations_list' : [],
            'name' : "",
            'order_no' : "",
            'image' : {},
            'latest_arrival' : '',
            'popular' : '',
            'description' : "",
            'category' : "",
            'sub_category' : "",
            'price' : 0,
            'discount' : 0,
            'variants' : [
                {'size' : "", 'color':"", 'quantity' : ""}
            ],
            'addon_for' : 3
		}
	}


	
	addProduct = async()=>{
		var form_data = new FormData()
        form_data.append('name', this.state.name);
        form_data.append('image', this.state.image);
        form_data.append('latest_arrival', this.state.latest_arrival);
        form_data.append('popular', this.state.popular);
        form_data.append('category', this.state.category);
        form_data.append('sub_category', this.state.sub_category);
        form_data.append('status', this.state.status);
        form_data.append('price', this.state.price);
        form_data.append('discount', this.state.discount);
        form_data.append('description', this.state.description);
        form_data.append('variants', JSON.stringify(this.state.variants));
        form_data.append('addon_for', this.state.addon_for);
        await this.state.locations.map(async(location)=>{
			form_data.append('locations', location.value)	
		});
		var response = await Api.PostRequestForm(form_data, 'ecommerce/products');
		if(response.status == 200){
			toast.success(response.message);
            this.props.navigate('/ecommerce/products')
		}
		else{
			toast.error(response.message);
		}
	}


    setVariant = async(index, section, value)=>{
        var variants = this.state.variants;
        if(section == 0){
            variants[index].size = value;
        }
        else if(section == 1){
            variants[index].color = value;
        }
        else{
            variants[index].quantity = value;   
        }
        this.setState({'variants' : variants});
    }


    deleteVariant = async(index) =>{
        var variants = this.state.variants;
        variants.splice(index, 1);
        this.setState({'variants' : variants});
    }


    addVariant = async()=>{
        var variants = this.state.variants;
        await variants.push({'size' : "", 'color':"", 'quantity' : ""});
        await this.setState({'variants' : variants});
    }


	render(){
		return(
			<>
                <div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">Ecommerce</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">New Product</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										{/* <button type="button" class="btn btn-success" onClick={()=>{this.setState({'show_add_model' :  true})}}>
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> String Type
										</button> */}
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
                                        <div className="row">
                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Name</label>
                                                <input className="form-control" value={this.state.name} onChange={(event) => {this.setState({'name': event.target.value})}} type="text" placeholder=""/>
                                            </div>
                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Price</label>
                                                <input className="form-control" value={this.state.price} onChange={(event) => {this.setState({'price': event.target.value})}} type="number" placeholder=""/>
                                            </div>

                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Discount</label>
                                                <input className="form-control" value={this.state.discount}  min={0} max={100} onChange={(event) => {this.setState({'discount': event.target.value})}} type="number" placeholder=""/>
                                            </div>

                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Image</label>
                                                <input className="form-control" onChange={(event)=>{this.setState({'image' : event.target.files[0]})}}  type="file" placeholder=""/>
                                            </div>
                                            
                                            <div class="col-6 my-2 form-group">
                                                <label className="text-left w-100">Category</label>
                                                <Select className="text-left" options={this.state.categories} label={'name'} onChange={(event)=>{this.setState({'category' : event.value})}}/>
                                            </div>
                                            
                                            <div class="col-6 my-2 form-group">
                                                <label className="text-left w-100">Sub Category</label>
                                                <Select className="text-left" options={this.state.sub_categories} onChange={(event)=>{this.setState({'sub_category' : event.value})}}/>
                                            </div>

                                            <div class="col-6 my-2 form-group">
                                                <label className="text-left w-100">Status</label>
                                                <Select className="text-left" options={this.status_list} onChange={(event)=>{this.setState({'status' : event.value})}}/>
                                            </div>
                                            
                                            <div class="col-6 my-2 form-group">
                                                <label className="text-left w-100">Popular</label>
                                                <Select className="text-left" options={this.status_list} onChange={(event)=>{this.setState({'popular' : event.value})}}/>
                                            </div>

                                            <div class="col-6 my-2 form-group">
                                                <label className="text-left w-100">Latest Arrival</label>
                                                <Select className="text-left" options={this.status_list} onChange={(event)=>{this.setState({'latest_arrival' : event.value})}}/>
                                            </div>


                                            <div class="col-6 my-2 form-group">
                                                <label className="text-left w-100">Addon For</label>
                                                <Select className="text-left" options={this.addon_for} onChange={(event)=>{this.setState({'addon_for' : event.value})}}/>
                                            </div>

                                            <div className="col-6 my-2 form-group">
                                                <label className="text-left w-100">Order No.</label>
                                                <input className="form-control" onChange={(event)=>{this.setState({'order_no' : event.target.value})}}  type="number" placeholder=""/>
                                            </div>

                                            <div class="col-12 py-3 mt-3 justify-content-between d-flex align-items-center flex-row" style={{"backgroundColor": "#EDEDED"}}>
                                                <h5 class="my-0">Locations</h5>
                                            </div>

                                            <div class="col-12 mt-4 form-group">
                                                <Select options={this.state.locations_list} onChange={(event)=>{this.setState({'locations' : event})}} isMulti={true}/>
                                            </div>

                                            <div class="col-12 py-3 mt-3 justify-content-between d-flex align-items-center flex-row" style={{"backgroundColor": "#EDEDED"}}>
                                                <h5 class="my-0">Description</h5>
                                            </div>

                                            <div class="col-12 my-2 form-group">
                                                <label className="text-left w-100">Description</label>
                                                <textarea className="form-control" name="description" value={this.state.description} onChange={(event)=>{this.setState({'description' : event.target.value})}}></textarea>
                                            </div>


                                            <div class="col-12 py-3 mt-3 justify-content-between d-flex align-items-center flex-row" style={{"backgroundColor": "#EDEDED"}}>
                                                <h5 class="my-0">Variants</h5>
                                            </div>


                                            <div class="col-12 mt-4">
                                                <table class="table w-50 table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Size</th>
                                                            <th>Color</th>
                                                            <th>Quantity</th>
                                                            <th><button onClick={()=>{this.addVariant()}} type="button" id="add-variant" class="btn btn-primary py-1 my-0" >Add</button></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="variant-container">
                                                        {this.state.variants.map((variant, index)=>{
                                                            return(
                                                                <tr>
                                                                    <td>
                                                                        <input className="form-control" value={variant.size} onChange={(event) => {this.setVariant(index, 0, event.target.value)}} type="text" placeholder=""/>
                                                                    </td>
                                                                    <td>
                                                                        <input className="form-control" value={variant.color} onChange={(event) => {this.setVariant(index, 1, event.target.value)}} type="text" placeholder=""/>
                                                                    </td>
                                                                    <td>
                                                                        <input className="form-control" value={variant.quantity} onChange={(event) => {this.setVariant(index, 2, event.target.value)}} type="number" placeholder=""/>
                                                                    </td>
                                                                    <td>
                                                                        <i class="fa fa-trash delete-variant" onClick={()=>{this.deleteVariant(index)}} aria-hidden="true" style={{'color':"red"}}></i>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })

                                                        }
                                                        
                                                    </tbody>
                                                </table>
                                            </div>


                                            <div className="col-12 ">
                                                <button type="submit" class="btn btn-success" onClick={()=>{this.addProduct()}}>Submit</button>
                                            </div>
                                        </div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </>
		)
	}

}

				
export default withRouter(NewProduct)