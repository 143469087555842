import React, { Component } from "react";

export class Default extends Component {
  render() {
    return (

      <>  
      
     
                     <div class="pcoded-main-container">
        <div class="pcoded-content">
          <div class="page-header">
            <div class="page-block">
              <div class="row align-items-center">
                <div class="col-md-12">
                  <div class="page-header-title">
                    <h5 class="m-b-10">Coupons</h5>
                  </div>
                  <ul class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
                    <li class="breadcrumb-item"><a href="#!">Membership</a></li>
                    <li class="breadcrumb-item"><a href="#!">Coupons</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">
                  Add section here
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
      
    )
  }
}

export default Default;

