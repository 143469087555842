import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';
import Select from 'react-select';

class StringServiceBookings extends Component {

	componentDidMount=async ()=>{
		await this.getStringServiceBookings();
		await this.getVendors();
		await this.getDeliveryBoys();
	}


	getVendors = async ()=>{
		var response = await Api.GetRequest({}, "string-vendors/list");
		if(response.status == 200){
			this.setState({'vendors' : response.vendors});
		}
		else{
			
		}
	}


	getDeliveryBoys = async ()=>{
		var response = await Api.GetRequest({}, "delivery-boys/list");
		if(response.status == 200){
			this.setState({'delivery_boys' : response.delivery_boys});
		}
		else{
			
		}
	}



	getStringServiceBookings = async()=>{
		var response = await Api.GetRequest({}, "string-service-bookings");
		if(response.status == 200){
			this.setState({'bookings' : response.bookings});
		}
		else{

		}
	}


	constructor(props) {
		super(props);
		this.state = {
			'bookings' : [],
			'vendors' : [],
			'delivery_boys' : []
		};
	}


	assignBookingToVendor = async(booking_id, vendor)=>{
		var data = {
			'booking_id' : booking_id,
			'vendor' : vendor.value
		}

		var response = await Api.PostRequest(data, 'string-booking/assign');
		if(response.status == 200){
			toast.success("Vendor assigned successfully.");
			await this.getStringServiceBookings();
		}
		else{
			toast.error("error");
		}
	}


	assignBookingToDeliveryBoy = async(booking_id, delivery_boy)=>{
		var data = {
			'booking_id' : booking_id,
			'delivery_boy' : delivery_boy.value
		}

		var response = await Api.PostRequest(data, 'string-booking/delivery-boy/assign');
		if(response.status == 200){
			toast.success("Delivery boy assigned successfully.");
			await this.getStringServiceBookings();
		}
		else{
			toast.error(response.message);
		}
	}
	
	render() {
		return (
			<>
				
				<div class="pcoded-main-container">
					<div class="pcoded-content">
						<div class="page-header">
							<div class="page-block">
								<div class="row align-items-center">
									<div class="col-md-6">
										<div class="page-header-title">
											<h5 class="m-b-10 text-left">Bookings</h5>
										</div>
										<ul class="breadcrumb">
											<li class="breadcrumb-item"><a href="index.html"><i class="feather icon-home"></i></a></li>
											<li class="breadcrumb-item"><a href="#!">Bookings</a></li>
										</ul>
									</div>
									<div class="col-md-6 text-right">
										{/* <button type="button" class="btn btn-success" onClick={()=>{this.setState({'show_add_model' :  true})}}>
											<i class="fas fa-plus" style={{ paddingRight: "10px" }}></i> String Type
										</button> */}
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-12">
								<div class="card">
									<div class="card-body">
									<div class="dt-responsive table-responsive">
										<table id="full-row-reorder" class="table table-striped table-bordered nowrap">
											<thead>
												<tr>
													<th>S. No.</th>
													<th>Name</th>
													<th>Service Type</th>
													<th>String Price</th>
													<th>Addon Price</th>
													<th>Total Amount</th>
													<th>Slot</th>
													<th>Vendor</th>
													<th>Delivery Boy</th>
													<th>Status</th>
													<th>Cretaed At</th>
													<th></th>
												</tr>
											</thead>
											
											<tbody>
												{this.state.bookings.map((booking, index)=>{
													return(
														<tr>
															<td>{index + 1}</td>
															<td>{booking.name}</td>
															<td>
																{booking.service_type == 0 &&
																	<div>Badminton</div>
																}
																{booking.service_type == 1 &&
																	<div>Tennis</div>
																}
															</td>
															<td className="text-right">₹ {booking.string_amount}</td>
															<td className="text-right">₹ {booking.addon_amount}</td>
															<td className="text-right">₹ {booking.addon_amount + booking.string_amount}</td>
															<td className="text-right">
																<span>{moment(booking.slot_date).format("DD-MM-YYYY")}</span>
																{booking.slot_time == 0 &&
																	<span className="mx-2">11:00 AM - 2:00 PM</span>
																}
																{booking.slot_time == 1 &&
																	<span className="mx-2">7:00 PM - 9:00 PM</span>
																}
															</td>
															<td>
																<Select 
																	menuPortalTarget={document.body}
																	value = {
																		this.state.vendors.filter(option => 
																			option.value == booking.vendor_id)
																		} 
																	onChange={(event) => {this.assignBookingToVendor(booking.id, event)}} 
																	options={this.state.vendors}/>
															</td>
															<td>
																<Select 
																	menuPortalTarget={document.body}
																	value = {
																		this.state.delivery_boys.filter(option => 
																			option.value == booking.delivery_boy_id)
																		} 
																	onChange={(event) => {this.assignBookingToDeliveryBoy(booking.id, event)}} 
																	options={this.state.delivery_boys}/>
															</td>
															<td>
																{booking.status == 0 &&
																	<span className="badge badge-warning">Pending</span>
																}
																{booking.status == 1 &&
																	<span className="badge badge-info">Assigned To Vendor</span>
																}
																{booking.status == 2 &&
																	<span className="badge badge-warning">Assigned To Delivery Boy</span>
																}
																{booking.status == 3 &&
																	<span className="badge badge-info">Picked Up From Customer</span>
																}
																{booking.status == 4 &&
																	<span className="badge badge-info">Delivered to Vendor</span>
																}
																{booking.status == 5 &&
																	<span className="badge badge-info">Ready To Deliver</span>
																}
																{booking.status == 6 &&
																	<span className="badge badge-success">Delivered</span>
																}
															</td>
															<td className="text-right">{moment(booking.created_at).format('lll')}</td>
															<td className="text-right">
																<Link to={`/string-service-booking/${booking.id}`} className="btn btn-info btn-sm mx-2"><i className="feather icon-eye"></i>&nbsp;View </Link>
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default StringServiceBookings;
